/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { IBankResponse } from 'src/types/IBankResponse';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as Yup from 'yup';
import { KTIcon } from "../../../helpers";
import { IAddCustomer, stateAddCustomer } from "./model/IAddCustomer";

export interface IQuestionType {
  id:        number;
  string_id: string;
  question:  string;
  language:  string;
}

const addCustomerFormValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 character')
    .max(300, 'Maximum 300 character')
    .required('Name is required'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 character')
    .max(300, 'Maximum 300 character')
    .required('Lastname is required'),
  username: Yup.string()
    .min(6, 'Minimum 6 character')
    .max(300, 'Maximum 300 character')
    .required('Lastname is required'),
  address: Yup.string()
    .min(6, 'Minimum 6 character')
    .max(300, 'Maximum 300 character')
    .required('Address is required'),
  pin: Yup.string()
    .min(6, 'Minimum 6 character')
    .max(300, 'Maximum 300 character')
    .required('PIN is required'),
  bank_id: Yup.string().required('Bank ID is required'),
  birthdate: Yup.string().required('Birthdate is required'),
  gender: Yup.string().required('Gender is required'),
  question: Yup.string().required('Question is required'),
  answer: Yup.string().required('Answer is required'),
})


const AddCustomer: FC = () => {
  const intl = useIntl();
  const [list, setList] = useState<IBankResponse[]>([])
  const [question, setQuestions] = useState<IQuestionType[]>([])
  const formik = useFormik<IAddCustomer>({
    initialValues: {
      ...stateAddCustomer,
    },
    validationSchema: addCustomerFormValidationSchema,
    onSubmit: (values) => {
      createData(values)
    },
  })
  const createData =  async (values: IAddCustomer) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}bank/nasabah/add`, values)
      if(response.status === 200 || response.status === 201) {
        Swal.fire({
          title: 'Success!',
          text: 'Success',
          icon: 'success',
          confirmButtonText: "OK"
        }).then((response) => {
          if(response.isConfirmed){
            window.location.reload()
          }
        })
      }
    } catch (e:any) {
      Swal.fire({
        title: 'Error!',
        text: e?.response?.data?.msg,
        icon: 'error',
      })
    }
  }

  const fetchListQuestions =  async () => {
    try {
      const  response = await axios.post(`${process.env.REACT_APP_API_URL}questions?language=en`, {
        country: "PH",
      })
      setQuestions(response?.data?.data)
    } catch (e) {
      setQuestions([])
    }
  }
  useEffect(()=> {
    fetchListQuestions()
  }, [])

  
  const fetchList =  async () => {
    try {
      const  response = await axios.post(`${process.env.REACT_APP_API_URL}data/dashboard/get-bank`, {
        country: "PH",
      })
      setList(response?.data?.data)
    } catch (e) {
      setList([])
    }
  }
  useEffect(()=> {
    fetchList()
  }, [])
  return (
    <div className="modal fade" id="kt_modal_add_user" aria-hidden="true">
      <div className="modal-dialog mw-650px">
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <KTIcon iconName="cross" className="fs-1" />
            </div>
          </div>

          <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
            <div className="text-center mb-13">
              <h1 className="mb-3"> {intl.formatMessage({ id: "MENU-CUSTOMER" })}</h1>
            </div>

            <div className="card-body p-9">
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                <label className="col-lg-4 fw-bold text-muted"> {intl.formatMessage({ id: "TABLE-NAME" })}</label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder= {intl.formatMessage({ id: "TABLE-NAME" })}
                    {...formik.getFieldProps('name')}
                  />
                   {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.name}</div>
                      </div>
                    )}
                </div>
              </div>
              <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                <label className="col-lg-4 fw-bold text-muted"> {intl.formatMessage({ id: "TABLE-LAST-NAME" })}</label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder= {intl.formatMessage({ id: "TABLE-LAST-NAME" })}
                    {...formik.getFieldProps('lastname')}
                  />
                   {formik.touched.lastname && formik.errors.lastname && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.lastname}</div>
                      </div>
                    )}
                </div>
              </div>
              <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                <label className="col-lg-4 fw-bold text-muted">Username</label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder={"Username"}
                    {...formik.getFieldProps('username')}
                  />
                  {formik.touched.username && formik.errors.username && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.username}</div>
                      </div>
                    )}
                </div>
              </div>
              <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                <label className="col-lg-4 fw-bold text-muted"> {intl.formatMessage({ id: "TABLE-ADDRESS" })}</label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="address"
                    {...formik.getFieldProps('address')}
                  />
                  {formik.touched.address && formik.errors.address && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.address}</div>
                      </div>
                    )}
                </div>
              </div>
              <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                <label className="col-lg-4 fw-bold text-muted">
                {intl.formatMessage({ id: "TABLE-PHONE-NUMBER" })}
                </label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Phone number"
                    {...formik.getFieldProps('phone')}
                  />
                   
                </div>
              </div>
              <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                <label className="col-lg-4 fw-bold text-muted">
                {intl.formatMessage({ id: "TABLE-EMAIL" })}
                </label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Email"
                    {...formik.getFieldProps('email')}
                  />
                   
                </div>
              </div>
              <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                <label className="col-lg-4 fw-bold text-muted"> {intl.formatMessage({ id: "TABLE-PIN" })}</label>
                <div className="col-lg-8">
                  <input
                    type="password"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="PIN"
                    {...formik.getFieldProps('pin')}
                  />
                  {formik.touched.pin && formik.errors.pin && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.pin}</div>
                      </div>
                    )}
                </div>
              </div>

              <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                  <label className="col-lg-4 fw-bold text-muted"> {intl.formatMessage({ id: "TABLE-QUESTION" })} </label>
                  <div className="col-lg-8">
                  <select
                    className='form-select form-select-solid form-select-lg'
                    {...formik.getFieldProps('question')}
                  >
                    <option value=''>Select Question</option>
                    {
                      question.map((item)=> {
                        return  <option value={(item.question)}>
                        {item.question}
                      </option>
                      })
                    }
                  </select>
                    {formik.touched.question && formik.errors.question && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.question}</div>
                        </div>
                      )}
                  </div>
                </div>

             
              <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                <label className="col-lg-4 fw-bold text-muted"> {intl.formatMessage({ id: "TABLE-ANSWER" })}</label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder={intl.formatMessage({ id: "TABLE-ANSWER" })}
                    {...formik.getFieldProps('answer')}
                  />
                  {formik.touched.answer && formik.errors.answer && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.answer}</div>
                      </div>
                    )}
                </div>
              </div>
              <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                <label className="col-lg-4 fw-bold text-muted"> {intl.formatMessage({ id: "TABLE-BIRTHDAY" })}</label>
                <div className="col-lg-8">
                  <input
                    type="date"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Birthday"
                    {...formik.getFieldProps('birthdate')}
                  />
                   {formik.touched.birthdate && formik.errors.birthdate && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.birthdate}</div>
                      </div>
                    )}
                </div>
              </div>
              <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                <label className="col-lg-4 fw-bold text-muted"> {intl.formatMessage({ id: "TABLE-BANK" })}</label>
                <div className="col-lg-8">
                <select
                  className='form-select form-select-solid form-select-lg'
                  {...formik.getFieldProps('bank_id')}
                >
                  <option value=''>Select bank</option>
                  {
                    list.map((item)=> {
                      return  <option value={Number(item.bank_id)}>
                      {item.name}
                    </option>
                    })
                  }
                </select>
                   {formik.touched.bank_id && formik.errors.bank_id && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.bank_id}</div>
                      </div>
                    )}
                </div>
              </div>
              <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                <label className="col-lg-4 fw-bold text-muted"> {intl.formatMessage({ id: "TABLE-GENDER" })}</label>
                <div className="col-lg-8">
                <select
                  className='form-select form-select-solid form-select-lg'
                  {...formik.getFieldProps('gender')}
                >
                  <option value=''>Select gender..</option>
                  <option value='Male'>
                    Male
                  </option>
                  <option value='Female'>Female</option>
                </select>
                   {formik.touched.gender && formik.errors.gender && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.gender}</div>
                      </div>
                    )}
                </div>
              </div>
              
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='submit' className='btn btn-primary' >
                  {intl.formatMessage({ id: "OTHER-SAVE-CHANGE" })}
                </button>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AddCustomer };

/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";

import axios from "axios";
import DataTable, { TableColumn } from "react-data-table-component";
import { FaThumbsUp } from "react-icons/fa";
import { KTIcon } from "src/_metronic/helpers";
import { exportToExcel } from "src/app/helpers/utils";
import { ICritismResponse } from "src/types/ICritismResponse";
import Swal from "sweetalert2";
import { ExcelSettings } from "./ExcelSettings";


const CritismWrapper: FC = () => {
  const intl = useIntl();
  const [list, setList] = useState<ICritismResponse[]>([])
  const [searchTerm, setSearchTerm] = useState("");

  const COLUMNS: TableColumn<ICritismResponse>[] = [
    {
      name: intl.formatMessage({ id: "TABLE-NAME" }),
      sortable: true,
      width: "",
      selector: (item) => item.name,
      cell: (item) =>  item.name
    },
    {
      name: intl.formatMessage({ id: "TABLE-EMAIL" }),
      sortable: true,
      width: "",
      selector: (item) => item.email,
      cell: (item) => item.email,
    },
    {
      name: intl.formatMessage({ id: "TABLE-BANK" }),
      sortable: true,
      width: "",
      selector: (item) => item.bank_name || '',
      cell: (item) => <a href={`/bank/${item.bank_id}`}>{item.bank_name}</a>,
    },
    {
      name:  intl.formatMessage({ id: "MENU-CUSTOMER" }),
      width: "",
      sortable: true,
      selector: (item) => item.nasabah_name || '',
      cell:  (item) => item.nasabah_name,
    },
    {
      name: 'Type',
      sortable: true,
      width: "",
      selector: (item) => item.type || '',
      cell: (item) => item.type
    },
    {
      name: intl.formatMessage({ id: "TABLE-CATEGORY" }),
      sortable: true,
      width: "",
      selector: (item) => item.category || '',
      cell: (item) => item.category
    },
    {
      name:  intl.formatMessage({ id: "TABLE-DESCRIPTION" }),
      sortable: true,
      width: "",
      selector: (item) => item.description || '',
      cell: (item) => <div>{`${item.description}`}</div>,
    },
    {
      name: 'Submit Date',
      sortable: true,
      width: "",
      selector: (item) => item.submit_date || '',
      cell: (item) => <div>{`${item.submit_date}`}</div>,
    },
    {
      name: 'Resolve Date',
      sortable: true,
      width: "",
      selector: (item) => item.resolve_date || '',
      cell: (item) => <div>{`${item.resolve_date}`}</div>,
    },
    {
      name: 'Action',
      sortable: true,
      width: "",
      cell: (item) => <div 
      style={{ cursor: "pointer" }}
            className={`btn btn-sm badge badge-light-success fw-bold my-2`}
      onClick={() =>
        Swal.fire({
          title: "Are you sure?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText:"Solved?",
          denyButtonText: `Cancel`,
        }).then((result) => {
          if (result.isConfirmed) {
            fetchSolved(item.id).then(
              (res: any) => {
                console.log(res);
                if (res?.status === 200 || res?.status === 201) {
                  Swal.fire("Success!", "", "success");
                  fetchList();
                } else {
                  Swal.fire(res?.response?.data?.msg, "", "error");
                }
              }
            );
          } else if (result.isDenied) {
            Swal.fire("Changes are not saved", "", "info");
          }
        })
      }><FaThumbsUp />
      </div>,
    },
  
  ];

  const fetchList =  async () => {
    try {
      const  response = await axios.post(`${process.env.REACT_APP_API_URL}data/dashboard/get-complain`, {
        country: "PH",
      })
      setList(response?.data?.data)
    } catch (e) {
      setList([])
    }
  }

  useEffect(()=> {
    fetchList()
  }, [])


  const filteredList = list.filter((item) =>
  Object.values(item).some(
    (value) =>
      value &&
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
  )
);


const fetchSolved = async (complain: number) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}data/dashboard/solve-complain`,
      {
        complain,
      }
    );
    return response;
  } catch (e) {
    return e;
  }
};

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU-CRITISM-SUGGESTION" })}
      </PageTitle>
      <div className="card">
        <div className="card-header border-0 pt-5">
          <div className="d-flex align-items-center gap-2">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">
                {intl.formatMessage({ id: "MENU-CRITISM-SUGGESTION" })}
              </span>
            </h3>
            <div>
              <button type='button' className='btn btn-sm btn-light-primary' onClick={() =>  exportToExcel(list, ExcelSettings, 'critism-and-suggestion')}>
                <KTIcon iconName='exit-up' className='fs-2' />
                Export
              </button>
            </div>
          </div>
          <div
            className="card-toolbar"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-trigger="hover"
            title="Click to add a user"
          >
            <input
              id="search"
              className="form-control form-control-solid w-250px"
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <div className="card-body py-3">
          {
            list.length> 0  && <DataTable columns={COLUMNS} data={filteredList} pagination  />
          }
        </div>
      </div>
    </>
  );
};

export { CritismWrapper };

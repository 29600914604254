import React from "react";
import { useIntl } from "react-intl";

export default function Loading() {
  const intl = useIntl();
  return (
    <span className="indicator-progress" style={{ display: "block", position:'absolute', zIndex:10 }}>
      {intl.formatMessage({ id: "OTHER.LOADING" })}{" "}
      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
    </span>
  );
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from "clsx";
import {
  addDays,
  addMonths,
  addYears,
  differenceInCalendarDays,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";
import enUS from "date-fns/locale/en-US";
import id from "date-fns/locale/id";
import {
  DateRangePicker
} from "react-date-range"; // Import the styles
import { useIntl } from "react-intl";
import { KTIcon } from "../../../helpers";
/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
  toggleBtnClass?: string;
  toggleBtnIconClass?: string;
  menuPlacement?: string;
  menuTrigger?: string;
  handleDateRangeChange?: any;
  dateRange?: any;
  timeStart?: any;
  timeEnd?: any;
  handleTimeStart?: any;
  handleTimeEnd?: any;
};

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -6)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

const RangeDatePicker = ({
  toggleBtnClass = "min-w-200px",
  menuPlacement = "bottom-end",
  menuTrigger = "{default: 'click', lg: 'hover'}",
  handleDateRangeChange,
  dateRange,
  timeStart,
  timeEnd,
  handleTimeStart,
  handleTimeEnd,
}: Props) => {
  // Calculate the maximum date (e.g., max year)
  const maxYear = new Date().getFullYear();
  // Calculate the maximum date (e.g., min year)
  const minYear = new Date().getFullYear() - 10;
  // Set the maximum date for the DateRangePicker
  const maxDate = new Date(maxYear, 11, 31); //
  const minDate = new Date(minYear, 11, 31); //
  const intl = useIntl();
  return (
    <div className="w-100">
      {/* begin::Menu toggle */}
      <a
        href="#"
        className={clsx("btn btn-icon ", toggleBtnClass)}
        data-kt-menu-trigger={menuTrigger}
        data-kt-menu-attach="parent"
        data-kt-menu-placement={menuPlacement}
      >
        <button
          disabled={false}
          type="button"
          className="btn btn-light-primary"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          onClick={() => console.log("e")}
        >
          <KTIcon iconName="filter" className="fs-2" />
          {intl.formatMessage({ id: "OTHER.RANGEDATE" })}
        </button>
      </a>
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-600px"
        data-kt-menu="true"
      >
        <div className="menu-item px-3 my-0">
          <DateRangePicker
            maxDate={maxDate}
            minDate={minDate}
            onChange={handleDateRangeChange}
            ranges={dateRange}
            locale={intl.locale === "id" ? id : enUS}
            inputRanges={[
              {
                label: intl.formatMessage({ id: "DATEPICKER.DAYS-UP-TO-TODAY" }),
                range(value) {
                  return {
                    startDate: addDays(
                      defineds.startOfToday,
                      (Math.max(Number(value), 1) - 1) * -1
                    ),
                    endDate: defineds.endOfToday,
                  };
                },
                getCurrentValue(range) {
                  if (!isSameDay(range.endDate, defineds.endOfToday))
                    return "-";
                  if (!range.startDate) return "∞";
                  return (
                    differenceInCalendarDays(
                      defineds.endOfToday,
                      range.startDate
                    ) + 1
                  );
                },
              },
              {
                label:  intl.formatMessage({ id: "DATEPICKER.DAYS-STARTING-TODAY" }),
                range(value) {
                  const today = new Date();
                  return {
                    startDate: today,
                    endDate: addDays(today, Math.max(Number(value), 1) - 1),
                  };
                },
                getCurrentValue(range) {
                  if (!isSameDay(range.startDate, defineds.startOfToday)) return '-';
                  if (!range.endDate) return '∞';
                  return differenceInCalendarDays(range.endDate, defineds.startOfToday) + 1;
                },
              },
            ]}
            staticRanges={[
              {
                label: intl.formatMessage({ id: "DATEPICKER.TODAY" }),
                range: () => ({
                  startDate: defineds.startOfToday,
                  endDate: defineds.endOfToday,
                }),
                isSelected(range) {
                  const definedRange = this.range();
                  return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                  );
                },
              },
              {
                label: intl.formatMessage({ id: "DATEPICKER.YESTERDAY" }),
                range: () => ({
                  startDate: defineds.startOfYesterday,
                  endDate: defineds.endOfYesterday,
                }),
                isSelected(range) {
                  const definedRange = this.range();
                  return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                  );
                },
              },

              {
                label: intl.formatMessage({ id: "DATEPICKER.TODAY" }),
                range: () => ({
                  startDate: defineds.startOfWeek,
                  endDate: defineds.endOfWeek,
                }),
                isSelected(range) {
                  const definedRange = this.range();
                  return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                  );
                },
              },
              {
                label: intl.formatMessage({ id: "DATEPICKER.THIS-WEEK" }),
                range: () => ({
                  startDate: defineds.startOfLastWeek,
                  endDate: defineds.endOfLastWeek,
                }),
                isSelected(range) {
                  const definedRange = this.range();
                  return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                  );
                },
              },
              {
                label: intl.formatMessage({ id: "DATEPICKER.LAST-WEEK" }),
                range: () => ({
                  startDate: defineds.startOfMonth,
                  endDate: defineds.endOfMonth,
                }),
                isSelected(range) {
                  const definedRange = this.range();
                  return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                  );
                },
              },
              {
                label: intl.formatMessage({ id: "DATEPICKER.THIS-MONTH" }),
                range: () => ({
                  startDate: defineds.startOfLastMonth,
                  endDate: defineds.endOfLastMonth,
                }),
                isSelected(range) {
                  const definedRange = this.range();
                  return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                  );
                },
              },
              {
                label: intl.formatMessage({ id: "DATEPICKER.LAST-YEAR" }),
                range: () => ({
                  startDate: startOfYear(addYears(new Date(), -1)),
                  endDate: endOfYear(addYears(new Date(), -1)),
                }),
                isSelected(range) {
                  const definedRange = this.range();
                  return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                  );
                },
              },
              {
                label: intl.formatMessage({ id: "DATEPICKER.THIS-YEAR" }),
                range: () => ({
                  startDate: startOfYear(new Date()),
                  endDate: endOfDay(new Date()),
                }),
                isSelected(range) {
                  const definedRange = this.range();
                  return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                  );
                },
              },
            ]}
          />
          <div className="d-flex flex-row w-100 gap-3 mt-5">
            <input
              className="form-control  form-control-solid"
              type="time"
              onChange={handleTimeStart}
              value={timeStart}
              id="appt"
              name="appt"
              min="09:00"
              max="18:00"
              required
            />
            <input
              className="form-control  form-control-solid"
              type="time"
              onChange={handleTimeEnd}
              value={timeEnd}
              id="appt"
              name="appt"
              min="09:00"
              max="18:00"
              required
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { RangeDatePicker };

/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
import axios from "axios";
import moment from "moment";
import { toAbsoluteUrl } from "src/_metronic/helpers";
import Swal from "sweetalert2";
import * as XLSX from "xlsx"; // Import XLSX with an asterisk (*)

const startTime = " 09:00:00";
const endTime = " 06:00:00";

const getNow = () => {
  return moment().format("YYYY-MM-DD HH:mm:ss");
};

const getNowFilter = () => {
  const date = moment().format("YYYY-MM-DD");
  return `${date}${startTime}`;
};

const getYesterdayFilter = () => {
  const date = moment().subtract(1, "days").format("YYYY-MM-DD");
  return `${date}${startTime}`;
};

const getNowFilterWithEndTime = () => {
  const date = moment().format("YYYY-MM-DD");
  return `${date}${endTime}`;
};

const getTommorowFilter = () => {
  const date = moment().add(1, "days").format("YYYY-MM-DD");
  return `${date}${endTime}`;
};

const oneWeekAgo = () => {
  // get first day of week
  const date = moment().startOf("week").format("YYYY-MM-DD");
  return `${date}${startTime}`;
};

const oneMonthAgo = () => {
  // get first day of month
  const date = moment().startOf("month").format("YYYY-MM-DD");
  return `${date}${startTime}`;
};

const threeMonthsAgo = () => {
  const date = moment().subtract(3, "months").format("YYYY-MM-DD");
  return `${date}${startTime}`;
};

const oneYearAgo = () => {
  // get first day of year
  const date = moment().startOf("year").format("YYYY-MM-DD");
  return `${date}${startTime}`;
};

const IDRFormat = (value) => {
  if (!value) return "0";
  const decimal = value.toString().split(".")[0];
  return decimal.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const humanDiff = (date) => {
  return moment(date).format("MMMM D, YYYY HH:mm:ss");
};

const roundedPercentage = (value) => {
  const digit = Math.ceil(value * 10) / 10;
  return `${digit}%`;
};

const dataAdditionalbase64 = (name, outlet_alias, department) => {
  return btoa(
    JSON.stringify({
      name: name,
      outlet_alias: outlet_alias,
      department: department,
    })
  );
};

const filterToStringExcel = (filter) => {
  const { start_date, end_date, start_time, end_time } = filter;
  const queryString = `?start_date=${start_date} ${start_time}&end_date=${end_date} ${end_time}`;
  return queryString;
};

const exportToExcel = (data, header, filename) => {
  let excel = [];
  let convertHeader = header.map((item) => item.name);
  excel.push(convertHeader);
  data.map((row) => {
    let temp = [];
    header.map((item) => {
      temp.push(row[item.key]);
    });
    excel.push(temp);
  });
  const ws = XLSX.utils.aoa_to_sheet(excel);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const dataBlob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = window.URL.createObjectURL(dataBlob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${filename}.xlsx`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const exportData = (EXPORT_URL, filter) => {
  Swal.fire({
    imageUrl: toAbsoluteUrl("/media/logos/logo-blackowl-gold.png"),
    title: "Loading...",
    showConfirmButton: false,
    allowOutsideClick: false,
  });
  const queryString = filterToStringExcel(filter);
  axios.get(`${EXPORT_URL}${queryString}`).then((response) => {
    const { data } = response.data;
    const { filename, host } = data;
    const urlParts = filename.split("/");
    const fileName = urlParts[urlParts.length - 1];
    axios
      .get(`http://${host}${filename}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}`); //or any other extension
        document.body.appendChild(link);
        link.click();
        Swal.close();
      });
  });
};

const formatDate = (originalDateTime) => {
  // Hapus "T" dengan metode replace
  const withoutT = originalDateTime.replace("T", " ");

  // Hapus ".000Z" dengan metode slice
  const withoutZ = withoutT.slice(0, -5);

  return withoutZ;
};

export {
  formatDate,
  exportData,
  exportToExcel,
  startTime,
  endTime,
  getNow,
  getYesterdayFilter,
  getNowFilterWithEndTime,
  getNowFilter,
  getTommorowFilter,
  oneWeekAgo,
  oneMonthAgo,
  threeMonthsAgo,
  oneYearAgo,
  IDRFormat,
  humanDiff,
  roundedPercentage,
  dataAdditionalbase64,
};

export interface IAddCustomer {
    name: string
    lastname: string
    username: string
    address: string
    phone: string
    email: string
    pin: string
    birthdate: string
    gender: string
    question: string
    answer: string
    bank_id: number
    is_operator: number
    country: string
  }
  
  export const stateAddCustomer: IAddCustomer = {
    name: '',
    lastname:'',
    username:'',
    address:'',
    phone:'',
    email:'',
    pin:'',
    birthdate: '',
    gender:'',
    question: '',
    answer: '',
    bank_id: 0,
    is_operator: 1,
    country: 'PH'
  }
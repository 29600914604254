/* eslint-disable react-hooks/exhaustive-deps */

import { KTIcon } from "../../../helpers"


const Search = ({onChange, value, placeholder}) => {
  return (
    <div className='card-title'>
      <div className='d-flex align-items-center position-relative my-1'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder={placeholder || 'Search'}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export {Search}

/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import StatisticPage from '../statistic/StatisticPage'


const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const [data, setData]= useState([])
  const fetchList =  async () => {
    try {
      const  response = await axios.post(`${process.env.REACT_APP_API_URL}data/dashboard/get-data`, {
        country: "PH",
      })
      setData(response?.data?.data)
    } catch (e) {
      setData([])
    }
  }
  useEffect(()=> {
    fetchList()
  },[])
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU-DASHBOARD'})}</PageTitle>
      <StatisticPage data={data}/>
    </>
  )
}

export { DashboardWrapper }


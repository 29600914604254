/* eslint-disable jsx-a11y/anchor-is-valid */

import axios from "axios";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom"; // Import useParams
import { IDRFormat } from "src/app/helpers/utils";
import DetailRequest from "src/app/modules/detail/DetailRequest";
import { IDetailTransaction } from "./model/IDetailTransaction";

const TransactionDetail: FC = () => {
  const intl = useIntl();
  const { id } = useParams();
  const [detail, setDetail] = useState<IDetailTransaction>();

  const fetchDetail = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}transaction/detail`,
        {
          id: id,
        }
      );
      setDetail(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchDetail();
  }, [id]);

  return (
    <>
      <div className="row g-5 g-xl-8">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  Transaction
                </span>
              </h3>
            </div>
            <div className="card-body py-3">
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">ID</label>

                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {detail?.transaction?.[0].nasabah_id}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Date</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {moment(detail?.transaction?.[0].date).format('DD/MM/YYYY')}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Customer</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                   <a href={`/customers/${detail?.transaction?.[0].nasabah_id}`}>{(detail?.transaction?.[0].nasabah_name)}</a>
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Operator</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                   {detail?.transaction?.[0].operator_name}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Total</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                   {IDRFormat(detail?.transaction?.[0].total)}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Request ID</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                   <a href={`/request/${detail?.transaction?.[0].request_id}`}>{(detail?.transaction?.[0].request_id)}</a>
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Bank</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                   <a href={`/bank/${detail?.transaction?.[0].bank_id}`}>{(detail?.transaction?.[0].bank_name)}</a>
                  </span>
                </div>
              </div>
      
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Longitude</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                   {(detail?.transaction?.[0].longitude)}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Latitude</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                   {(detail?.transaction?.[0].latitude)}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Address</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                   {(detail?.transaction?.[0].address)}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Notes</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                   {(detail?.transaction?.[0].notes)}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Status</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                   {(detail?.transaction?.[0].status)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DetailRequest transaction={detail?.transaction_detail}/>
        {/* <LatestTransaction transaction={detail?.request_transaction}/> */}
        {/* <LatestRequest transaction={detail?.nasabah_request}/>
        <LatestTransaction transaction={detail?.nasabah_transaction}/> */}
      </div>
    </>
  );
};

export { TransactionDetail };

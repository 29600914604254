export interface TypeLogs {
    TYPE_ERROR: number
    TYPE_ACCCESS: number
    TYPE_HIT_API: number
    TYPE_USER: number
}

export const dataTypeLogs : TypeLogs = {
    TYPE_ERROR: 1,
    TYPE_ACCCESS: 2,
    TYPE_HIT_API: 3,
    TYPE_USER: 4,
}
import moment from "moment";
import { useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { KTIcon } from "src/_metronic/helpers";
import { IDRFormat, exportToExcel } from "src/app/helpers/utils";
import { NasabahRequestElement } from "src/app/pages/customers/model/IDetailCustomer";
import { ExcelSettings } from "./ExcelSettings";
export default function LatestRequest({ transaction, bank = false }) {
  const [searchTerm, setSearchTerm] = useState("");
  const COLUMNS: TableColumn<NasabahRequestElement>[] = [
    {
      name: "Date",
      sortable: true,
      width: "120px",
      selector: (item) => moment(item.date).format("DD/MM/YYYY").toString(),
      cell: (item) => <div>{moment(item.date).format("DD/MM/YYYY")}</div>,
    },
    {
      name: "Request ID",
      sortable: true,
      width: "140px",
      selector: (item) => item?.request_id || "",
      cell: (item) => <div><a href={`/request/${item.request_id}`}>{item.request_id}</a></div>,
    },
    {
      name: bank ? "Customer" : "Bank",
      sortable: true,
      width: "140px",
      selector: (item:any) => bank ? item.nasabah_name : item.bank_name,
      cell: (item) => <a href={bank ? `/customers/${item.nasabah_id}` : `/bank/${item.bank_id}`}>{bank ? item.nasabah_name : item.bank_name}</a>,
      
    },
    {
      name: "Total",
      sortable: true,
      width: "140px",
      selector: (item) => item.total || "",
      cell: (item) => <div>{IDRFormat(item.total)}</div>,
    },
    {
      name: "Status",
      sortable: true,
      selector: (item) => item.status,
      cell: (item) => <div>{item.status}</div>,
    },
    {
      name: "Longitude",
      sortable: true,
      width: "140px",
      selector: (item) => item.longitude,
      cell: (item) => <div>{item.longitude}</div>,
    },
    {
      name: "Latitude",
      sortable: true,
      width: "140px",
      selector: (item) => item.latitude,
      cell: (item) => <div>{item.latitude}</div>,
    },
    {
      name: "Address",
      sortable: true,
      selector: (item) => item.address,
      cell: (item) => <div>{item.address}</div>,
    },
    {
      name: "Notes",
      sortable: true,
      selector: (item) => item.notes,
      cell: (item) => <div>{item.notes}</div>,
    },
    {
      name: "Arrival Time",
      sortable: true,
      selector: (item) => moment(item.arrival_time).format('DD/MM/YYYY'),
      cell: (item) => <div>{ moment(item.arrival_time).format('DD/MM/YYYY')}</div>,
    },
  ];

  const filteredList = transaction?.filter((item) =>
    Object.values(item as NasabahRequestElement).some(
      (value) =>
        value &&
        value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <div className="col-xl-12">
      <div className="card">
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">
              Latest Request
            </span>
          </h3>
          <div
            className="card-toolbar gap-2"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-trigger="hover"
            title="Click to add a user"
          >
            <div>
              <button
                type="button"
                className="btn btn-sm btn-light-primary"
                onClick={() => exportToExcel(transaction, ExcelSettings, "LatestRequest")}
              >
                <KTIcon iconName="exit-up" className="fs-2" />
                Export
              </button>
            </div>
            <input
              id="search"
              className="form-control form-control-solid w-250px"
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="card-body py-3">
          {" "}
          <DataTable columns={COLUMNS} data={filteredList} pagination />
        </div>
      </div>
    </div>
  );
}

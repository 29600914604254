// Export settings
export const ExcelSettings = [
    {
      name: "ID",
      key: "id",
    },
    {
      name: "Name",
      key: "name",
    },
    {
      name: "Email",
      key: "email",
    },
    {
      name: "Bank ID",
      key: "bank_id",
    },
    {
      name: "Bank Name",
      key: "bank_name",
    },
    {
      name: "Customer ID",
      key: "nasabah_id",
    },
    {
      name: "Customer Name",
      key: "nasabah_name",
    },
    {
      name: "Type",
      key: "type",
    },
    {
      name: "Category",
      key: "category",
    },
    {
      name: "Description",
      key: "description",
    },
    {
      name: "Submit Date",
      key: "submit_date",
    },
    {
      name: "Resolve Date",
      key: "resolve_date",
    },
    {
      name: "Country",
      key: "country",
    },
  ]

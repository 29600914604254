export interface IAddAdmin {
    name: string
    phone: string
    email: string
    password: string
    type: number
    bank_id: number
  }
  
  export const stateAddAdmin: IAddAdmin = {
    name: '',
    phone:'',
    email:'',
    password: '',
    bank_id: 0,
    type: 1
  }
export interface IEditDefaultItem {
    img: string
    category: string
    name: string
    description: string
    perkg: number
    country: string
    metric: string
  }
  
  export const stateEditDefaultItem: IEditDefaultItem = {
    img:'',
    category:'',
    name:'',
    description:'',
    perkg:0,
    country: "PH",
    metric: '',
  }
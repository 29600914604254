/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { KTIcon } from "src/_metronic/helpers";
import { PageTitle } from "../../../_metronic/layout/core";

import axios from "axios";
import moment from "moment";
import DataTable, { TableColumn } from "react-data-table-component";
import { exportToExcel } from "src/app/helpers/utils";
import { useStore } from "src/app/store/store";
import { IListCustomerResponse } from "src/types/IListCustomerResponse";
import Swal from "sweetalert2";
import { ExcelSettings } from "./ExcelSettings";

const CustomersWrapper: FC = () => {
  const intl = useIntl();
  const [list, setList] = useState<IListCustomerResponse[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const {setEditCustomer} = useStore()
  const COLUMNS: TableColumn<IListCustomerResponse>[] = [
    {
      name: "Action",
      sortable: false,
      width: "170px",
      cell: (item) => (
        <div className="d-flex  align-items-center" style={{ width: 300 }}>
          <div
            onClick={() => setEditCustomer(item)}
            className=" badge"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_edit_user"
            style={{cursor: 'pointer'}}
          >
            <KTIcon iconName="pencil" className="fs-3" />
          </div>
          <div
            onClick={() =>
              Swal.fire({
                title: "Are you sure?",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText:
                  item.status === 1 ? "Yes, set inactive" : "Yes, set active",
                denyButtonText: `Cancel`,
              }).then((result) => {
                if (result.isConfirmed) {
                  fetchChangeStatus(item.status, item.nasabah_id).then(
                    (res: any) => {
                      console.log(res);
                      if (res?.status === 200 || res?.status === 201) {
                        Swal.fire("Saved!", "", "success");
                        fetchList();
                      } else {
                        Swal.fire(res?.response?.data?.msg, "", "error");
                      }
                    }
                  );
                } else if (result.isDenied) {
                  Swal.fire("Changes are not saved", "", "info");
                }
              })
            }
            style={{ cursor: "pointer" }}
            className={`btn btn-sm badge badge-light-${
              item.status === 1 ? "danger" : "success"
            }  fw-bold my-2`}
          >
            {" "}
            {item.status === 1 ? "Set In Active" : "Set Active"}
          </div>
        </div>
      ),
    },
    {
      name: "ID",
      sortable: true,
      selector: (item: IListCustomerResponse) => item.nasabah_id,
      width: "80px",
      cell: (item: IListCustomerResponse) => <div>{item.nasabah_id}</div>,
    },
    {
      name: intl.formatMessage({ id: "TABLE-NAME" }),
      sortable: true,
      selector: (item: IListCustomerResponse) => item.name,
      width: "",
      cell: (item: IListCustomerResponse) => (
        <a href={`/customers/${item.nasabah_id}`}>{item.name}</a>
      ),
    },
    {
      name: intl.formatMessage({ id: "TABLE-LAST-NAME" }),
      sortable: true,
      selector: (item: IListCustomerResponse) => item.lastname,
      width: "",
      cell: (item: IListCustomerResponse) => item.lastname,
    },
    {
      name: intl.formatMessage({ id: "TABLE-ADDRESS" }),
      width: "",
      cell: (item: IListCustomerResponse) => item.address,
    },
    {
      name: intl.formatMessage({ id: "TABLE-PHONE-NUMBER" }),
      sortable: true,
      width: "",
      selector: (item: IListCustomerResponse) => item.phone,
      cell: (item: IListCustomerResponse) => item.phone,
    },
    {
      name: intl.formatMessage({ id: "TABLE-EMAIL" }),
      sortable: true,
      width: "",
      selector: (item: IListCustomerResponse) => item.email,
      cell: (item: IListCustomerResponse) => item.email,
    },
    {
      name: intl.formatMessage({ id: "TABLE-BIRTHDAY" }),
      sortable: false,
      width: "",
      cell: (item: IListCustomerResponse) =>
        moment(item.birthdate).format("DD/MM/YYYY"),
    },
    {
      name: intl.formatMessage({ id: "TABLE-GENDER" }),
      sortable: true,
      width: "",
      selector: (item: IListCustomerResponse) => item.gender,
      cell: (item: IListCustomerResponse) => item.gender,
    },
    {
      name: intl.formatMessage({ id: "TABLE-STATUS" }),
      sortable: false,
      width: "",
      cell: (item: IListCustomerResponse) => (
        <div
          className={`${item.status === 1 ? "text-success" : "text-danger"}`}
        >
          {item.status === 1 ? "Active" : "InActive"}
        </div>
      ),
    },
  ];

  const fetchList = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}profile/nasabah/all`,
        {
          country: "PH",
        }
      );
      setList(response?.data?.data);
    } catch (e) {
      setList([]);
    }
  };

  const fetchChangeStatus = async (status: number, nasabah_id: number) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}data/dashboard/${
          status === 1 ? "set-inactive-nasabah" : "set-active-nasabah"
        }`,
        {
          nasabah_id,
          status: status === 1 ? 0 : 1,
        }
      );
      return response;
    } catch (e) {
      return e;
    }
  };

  useEffect(() => {
    fetchList();
  }, []);

  const filteredList = list.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU-CUSTOMER" })}
      </PageTitle>
      <div className="card">
        <div className="card-header border-0 pt-5">
          <div className="d-flex align-items-center gap-2">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">
                {intl.formatMessage({ id: "MENU-CUSTOMER" })}
              </span>
            </h3>

            <div
              className="card-toolbar"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-bs-trigger="hover"
              title="Click to add a user"
            >
              <a
                href="#"
                className="btn btn-sm btn-light-primary"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_add_user"
              >
                <KTIcon iconName="plus" className="fs-3" />
                {intl.formatMessage({ id: "MENU-CUSTOMER" })}
              </a>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-sm btn-light-primary"
                onClick={() => exportToExcel(list, ExcelSettings, "customer")}
              >
                <KTIcon iconName="exit-up" className="fs-2" />
                Export
              </button>
            </div>
          </div>
          <div
            className="card-toolbar"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-trigger="hover"
            title="Click to add a user"
          >
            <input
              id="search"
              className="form-control form-control-solid w-250px"
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <div className="card-body py-3">
          <DataTable columns={COLUMNS} data={filteredList} pagination />
        </div>
      </div>
    </>
  );
};

export { CustomersWrapper };

export interface IAddDefaultItem {
    itemCode: string
    img: string
    category: string
    name: string
    description: string
    perkg: number
    country: string
    metric: string
  }
  
  export const stateAddDefaultItem: IAddDefaultItem = {
    itemCode:'',
    img:'',
    category:'',
    name:'',
    description:'',
    perkg:0,
    country: "PH",
    metric: '',
  }
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { KTIcon } from "../../../helpers";
import { IAddDefaultItem, stateAddDefaultItem } from "./model/IAddDefaultItem";
export interface IMetricType {
  id:          number;
  metric_name: string;
  name:        string;
}

const addDefaultItemSchema = Yup.object().shape({
  img: Yup.string().required("Img is required"),
  itemCode: Yup.string().required("Item Code is required"),
  category: Yup.string().required("Category is required"),
  name: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required"),
  perkg: Yup.number().required("Per KG is required"),
  metric:  Yup.string().required("Metric is required"),
});

const AddDefaultItem: FC = () => {
  const intl = useIntl();
  const [file, setFile]= useState(null)
  const [list, setList]= useState<IMetricType[]>([])
  const formik = useFormik<IAddDefaultItem>({
    initialValues: {
      ...stateAddDefaultItem,
    },
    validationSchema: addDefaultItemSchema,
    onSubmit:async (values) => {
    
    },
  });
  const createData = async (values: IAddDefaultItem) => {
    try {
      const formData = new FormData();

      // Append form values to FormData
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}data/dashboard/add-default-item`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Set content type to FormData
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        Swal.fire({
          title: "Success!",
          text: "Success",
          icon: "success",
          confirmButtonText: "OK",
        }).then((response) => {
          console.log(response);
          if (response.isConfirmed) {
            window.location.reload();
          }
        });
      }
    } catch (e: any) {
      Swal.fire({
        title: "Error!",
        text: e?.response?.data?.msg,
        icon: "error",
      });
    }
  };
  
  const onchangeFile = (e) => {
    setFile(e.target.files[0])
    formik.setFieldValue('img', e.target.files[0]);
  }

  const fetchList =  async () => {
    try {
      const  response = await axios.post(`${process.env.REACT_APP_API_URL}bank/item/metric`, {
        country: "PH",
      })
      setList(response?.data?.data)
    } catch (e) {
      setList([])
    }
  }
  useEffect(()=> {
    fetchList()
  }, [])

  return (
    <div className="modal fade" id="kt_modal_default_item" aria-hidden="true">
      <div className="modal-dialog mw-650px">
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <KTIcon iconName="cross" className="fs-1" />
            </div>
          </div>

          <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
            <div className="text-center mb-13">
              <h1 className="mb-3">
                {" "}
                {intl.formatMessage({ id: "MENU-DEFAULT-ITEM" })}
              </h1>
            </div>

            <div className="card-body p-9">
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
                <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                  <label className="col-lg-4 fw-bold text-muted">
                    Choose File
                  </label>
                  <div className="col-lg-8">
                    <input
                      type="file"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="Chose file"
                      onChange={(e) => onchangeFile(e)}
                    />
                     { (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.img}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                  <label className="col-lg-4 fw-bold text-muted">
                    {intl.formatMessage({ id: "TABLE-ITEM-CODE" })}
                  </label>
                  <div className="col-lg-8">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="Item Code"
                      {...formik.getFieldProps("itemCode")}
                    />
                    { (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.itemCode}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                  <label className="col-lg-4 fw-bold text-muted">
                    {intl.formatMessage({ id: "TABLE-CATEGORY" })}
                  </label>
                  <div className="col-lg-8">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="Category"
                      {...formik.getFieldProps("category")}
                    />
                    {(
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.category}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                  <label className="col-lg-4 fw-bold text-muted">
                    {intl.formatMessage({ id: "TABLE-NAME" })}
                  </label>
                  <div className="col-lg-8">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="name"
                      {...formik.getFieldProps("name")}
                    />
                    { (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.name}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                  <label className="col-lg-4 fw-bold text-muted">
                    {intl.formatMessage({ id: "TABLE-DESCRIPTION" })}
                  </label>
                  <div className="col-lg-8">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="description"
                      {...formik.getFieldProps("description")}
                    />
                    { (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.description}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                  <label className="col-lg-4 fw-bold text-muted"> Metric </label>
                  <div className="col-lg-8">
                  <select
                    className='form-select form-select-solid form-select-lg'
                    {...formik.getFieldProps('metric')}
                  >
                    <option value=''>Select Metric</option>
                    {
                      list.map((item)=> {
                        return  <option value={(item.metric_name)}>
                        {item.metric_name}
                      </option>
                      })
                    }
                  </select>
                    {formik.touched.metric && formik.errors.metric && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.metric}</div>
                        </div>
                      )}
                  </div>
                </div>

                <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                  <label className="col-lg-4 fw-bold text-muted">
                    {intl.formatMessage({ id: "TABLE-PRICE-PER-KG" })}
                  </label>
                  <div className="col-lg-8">
                    <input
                      type="number"
                      className="form-control form-control-lg form-control-solid"

                      {...formik.getFieldProps("perkg")}
                    />
                    {(
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.perkg}
                        </div>
                      </div>
                    )}
                  </div>
                </div>


             
                <div className="card-footer d-flex justify-content-end py-6 px-9">
                  <div  className='btn btn-primary'  onClick={() => createData(formik.values)}>
                    {intl.formatMessage({ id: "OTHER-SAVE-CHANGE" })}
                  </div>
                 
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AddDefaultItem };

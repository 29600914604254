/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { IDRFormat } from "src/app/helpers/utils";
import { toAbsoluteUrl } from "../../../helpers";

type Props = {
  className: string;
  image: string;
  title: string;
  value: number;
};

const StatisticsWidget: React.FC<Props> = ({
  className,
  image,
  title,
  value,
}) => {
  return (
    <div
      className={`card bgi-no-repeat ${className}`}
      style={{
        backgroundPosition: "right top",
        backgroundSize: "30% auto",
        backgroundImage: `url(${toAbsoluteUrl("/media/svg/shapes/" + image)})`,
      }}
    >
      {/* begin::Body */}
      <div className="card-body">
        <a
          href="#"
          className="card-title fw-bold text-muted text-hover-primary fs-4"
        >
          {title}
        </a>

        <div
          className="fw-bold text-primary my-6"
          style={{
            fontSize: 32,
          }}
        >
          {IDRFormat(value)}
        </div>
      </div>
      {/* end::Body */}
    </div>
  );
};

export { StatisticsWidget };

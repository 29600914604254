// Export settings
export const ExcelSettings = [
    {
      name: "Status",
      key: "status",
    },
    {
      name: "Bank ID",
      key: "bank_id",
    },
    {
      name: "Name",
      key: "name",
    },
    {
      name: "Address",
      key: "address",
    },
    {
      name: "Sales",
      key: "sales",
    },
    {
      name: "Transaction",
      key: "transaction",
    },
    {
      name: "Latitude",
      key: "latitude",
    },
    {
      name: "Longitude",
      key: "longitude",
    },
    {
      name: "City",
      key: "kota",
    },
    {
      name: "Village",
      key: "kelurahan",
    },
    {
      name: "Province",
      key: "kecamatan",
    },
    {
      name: "Bank Phone",
      key: "phone",
    },
    {
      name: "Bank Email",
      key: "email",
    },
    {
      name: "Vision",
      key: "visi",
    },
    {
      name: "Mission",
      key: "misi",
    },
    {
      name: "Start Hour",
      key: "start_hour",
    },
    {
      name: "Close Hour",
      key: "close_hour",
    },
    {
      name: "Delivery start hour",
      key: "delivery_start_hour",
    },
    {
      name: "Delivery close hour",
      key: "delivery_close_hour",
    },
    {
      name: "is Delivery?",
      key: "is_delivery",
    },
    {
      name: "Monday",
      key: "monday",
    },
    {
      name: "Tuesday",
      key: "tuesday",
    },
    {
      name: "Wednesday",
      key: "wednesday",
    },
    {
      name: "Thursday",
      key: "thursday",
    },
    {
      name: "Friday",
      key: "friday",
    },
    {
      name: "Saturday",
      key: "saturday",
    },
    {
      name: "Sunday",
      key: "sunday",
    },
    {
      name: "Delivery Monday",
      key: "delivery_monday",
    },
    {
      name: "Delivery Tuesday",
      key: "delivery_tuesday",
    },
    {
      name: "Delivery Wednesday",
      key: "delivery_wednesday",
    },
    {
      name: "Delivery Thursday",
      key: "delivery_thursday",
    },
    {
      name: "Delivery Friday",
      key: "delivery_friday",
    },
    {
      name: "Delivery Saturday",
      key: "delivery_saturday",
    },
    {
      name: "Delivery Sunday",
      key: "delivery_sunday",
    },
   
  ]

import { Route, Routes } from 'react-router-dom'
import StatisticPage from 'src/app/pages/statistic/StatisticPage'
import { PublicLayout } from './PublicLayout'

const PublicPage = () => (
  <Routes>
    <Route element={<PublicLayout />}>
      <Route path='statistic' element={<StatisticPage />} />
      <Route index element={<StatisticPage />} />
    </Route>
  </Routes>
)

export { PublicPage }


import { FC, Suspense, lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { MenuTestPage } from '../pages/MenuTestPage'
import { AdminWrapper } from '../pages/admin/AdminWrapper'
import { BankWrapper } from '../pages/bank/BankWrapper'
import { BankDetail } from '../pages/bank/[id]'
import { CritismWrapper } from '../pages/critism-suggestion/CritismWrapper'
import { CustomersWrapper } from '../pages/customers/CustomersWrapper'
import { DetailCustomer } from '../pages/customers/[id]'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { SalesBOI } from "../pages/data/salesboi"
import { SalesBOKG } from "../pages/data/salesbokg"
import { DefaultItemWrapper } from '../pages/default-item/DefaultItemWrapper'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { RequestDetail } from '../pages/request/[id]'
import { TonaseWrapper } from '../pages/tonase/TonaseWrapper'

import { DetailItem } from '../pages/tonase/item/[id]'
import { TransactionWrapper } from '../pages/transaction/TransactionWrapper'
import { TransactionDetail } from '../pages/transaction/[id]'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path="/data/salesboi" element={<SalesBOI />} />
        <Route path="/data/salesbokg" element={<SalesBOKG />} />
        {/* // new pages */}
        <Route path='customers' element={<CustomersWrapper />} />
        <Route path='customers/:id' element={<DetailCustomer />} />
        <Route path='request/:id' element={<RequestDetail />} />
        <Route path='tonase' element={<TonaseWrapper />} />
        <Route path='tonase/item/:id' element={<DetailItem />} />
        <Route path='default-item' element={<DefaultItemWrapper />} />
        <Route path='bank' element={<BankWrapper />} />
        <Route path='bank/:id' element={<BankDetail />} />
        <Route path='admin' element={<AdminWrapper />} />
        <Route path='transaction' element={<TransactionWrapper />} />
        <Route path='/transaction/:id' element={<TransactionDetail />} />
        <Route path='critism-suggestion' element={<CritismWrapper />} />

        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const SweetAlert = {
    success: (title: string, text: string) => {
        MySwal.fire({
            icon: 'success',
            heightAuto: false,
            title,
            text,
        })
    },
    error: (title: string, text: string) => {
        MySwal.fire({
            icon: 'error',
            heightAuto: false,
            title,
            text,
        })
    }
}

export default SweetAlert


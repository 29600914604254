/* eslint-disable jsx-a11y/anchor-is-valid */

import axios from "axios";
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom"; // Import useParams
import { IDRFormat } from "src/app/helpers/utils";
import LatestRequest from "src/app/modules/latest-request/LatestRequest";
import LatestTransaction from "src/app/modules/latest-transaction/LatestTransaction";
import { IDetailItem } from "./model/IDetailItem";

const DetailItem: FC = () => {
  const intl = useIntl();
  const { id } = useParams();
  const [detail, setDetail] = useState<IDetailItem>();

  const fetchDetail = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}item/detail`,
        {
          id: id,
        }
      );
      setDetail(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchDetail();
  }, []);

  return (
    <>
      <div className="row g-5 g-xl-8">
        <div className="col-xl-6">
          <div className="card">
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  {intl.formatMessage({ id: "MENU-CUSTOMER-EDIT" })}
                </span>
              </h3>
            </div>
            <div className="card-body py-3">

            <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Item Image</label>

                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                  <div style={{width: 100, height: 100}}><img src={detail?.item?.[0]?.image_path} style={{objectFit: 'cover', width: 100, height: 100}}/></div>
                  </span>
                </div>
              </div>

              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Item ID</label>

                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {detail?.item?.[0]?.item_id}
                  </span>
                </div>
              </div>

              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Item Code</label>

                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {detail?.item?.[0]?.item_code}
                  </span>
                </div>
              </div>


              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Item Name</label>

                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {detail?.item?.[0]?.name}
                  </span>
                </div>
              </div>


              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Description</label>

                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {detail?.item?.[0]?.description}
                  </span>
                </div>
              </div>


              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Item Price</label>

                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {IDRFormat(detail?.item?.[0]?.price)}
                  </span>
                </div>
              </div>

              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Item PerKG</label>

                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {IDRFormat(detail?.item?.[0]?.perkg)}
                  </span>
                </div>
              </div>
              
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Item Status</label>

                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {(detail?.item?.[0]?.status)}
                  </span>
                </div>
              </div>

              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Bank</label>

                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    <a href={`/bank/${detail?.item?.[0]?.bank_id}`}>{(detail?.item?.[0]?.bank_name)}</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        <LatestRequest transaction={detail?.item_request}/>
        <LatestTransaction transaction={detail?.item_transaction}/>
      </div>
    </>
  );
};

export { DetailItem };

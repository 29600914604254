/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { IBankResponse } from 'src/types/IBankResponse';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as Yup from 'yup';
import { KTIcon } from "../../../helpers";
import { IAddAdmin, stateAddAdmin } from "./model/IAddAdmin";


const addAdminFormValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 character')
    .max(300, 'Maximum 300 character')
    .required('Name is required'),
 
  phone: Yup.string()
    .min(6, 'Minimum 6 character')
    .max(300, 'Maximum 300 character')
    .required('Phone is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(6, 'Minimum 6 character')
    .max(300, 'Maximum 300 character')
    .required('Email is required'),
  password: Yup.string()
    .min(6, 'Minimum 6 character')
    .max(300, 'Maximum 300 character')
    .required('Password is required'),
  bank_id: Yup.string().required('Bank ID is required'),
  type: Yup.string().required('Type is required'),
})


const AddAdmin: FC = () => {
  const intl = useIntl();
  const [list, setList] = useState<IBankResponse[]>([])
  const formik = useFormik<IAddAdmin>({
    initialValues: {
      ...stateAddAdmin,
    },
    validationSchema: addAdminFormValidationSchema,
    onSubmit: (values) => {
      createData(values)
    },
  })
  const createData =  async (values: IAddAdmin) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}data/dashboard/save-operator`, {
        bank_id: Number(values.bank_id),
        email: values.email,
        name: values.name,
        password: values.password,
        phone: values.phone,
        type: Number(values.type)
      })
      if(response.status === 200 || response.status === 201) {
        Swal.fire({
          title: 'Success!',
          text: 'Success',
          icon: 'success',
          confirmButtonText: "OK"
        }).then((response) => {
          if(response.isConfirmed){
            window.location.reload()
          }
        })
      }
    } catch (e:any) {
      Swal.fire({
        title: 'Error!',
        text: e?.response?.data?.msg,
        icon: 'error',
      })
    }
  }
  
  const fetchList =  async () => {
    try {
      const  response = await axios.post(`${process.env.REACT_APP_API_URL}data/dashboard/get-bank`, {
        country: "PH",
      })
      setList(response?.data?.data)
    } catch (e) {
      setList([])
    }
  }
  useEffect(()=> {
    fetchList()
  }, [])
  return (
    <div className="modal fade" id="kt_modal_add_admin" aria-hidden="true">
      <div className="modal-dialog mw-650px">
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <KTIcon iconName="cross" className="fs-1" />
            </div>
          </div>

          <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
            <div className="text-center mb-13">
              <h1 className="mb-3"> {intl.formatMessage({ id: "MENU-ADMIN" })}</h1>
            </div>

            <div className="card-body p-9">
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                <label className="col-lg-4 fw-bold text-muted"> {intl.formatMessage({ id: "TABLE-NAME" })}</label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder= {intl.formatMessage({ id: "TABLE-NAME" })}
                    {...formik.getFieldProps('name')}
                  />
                   {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.name}</div>
                      </div>
                    )}
                </div>
              </div>
              
              
             
              <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                <label className="col-lg-4 fw-bold text-muted">
                {intl.formatMessage({ id: "TABLE-PHONE-NUMBER" })}
                </label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Phone number"
                    {...formik.getFieldProps('phone')}
                  />
                   {formik.touched.phone && formik.errors.phone && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.phone}</div>
                      </div>
                    )}
                </div>
              </div>
              
              <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                <label className="col-lg-4 fw-bold text-muted">
                {intl.formatMessage({ id: "TABLE-EMAIL" })}
                </label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Email"
                    {...formik.getFieldProps('email')}
                  />
                   {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.email}</div>
                      </div>
                    )}
                </div>
              </div>
              <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                <label className="col-lg-4 fw-bold text-muted"> {intl.formatMessage({ id: "TABLE-PASSWORD" })}</label>
                <div className="col-lg-8">
                  <input
                    type="password"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Password"
                    {...formik.getFieldProps('password')}
                  />
                  {formik.touched.password && formik.errors.password && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.password}</div>
                      </div>
                    )}
                </div>
              </div>
            
              
             
              <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                <label className="col-lg-4 fw-bold text-muted"> {intl.formatMessage({ id: "TABLE-BANK" })}</label>
                <div className="col-lg-8">
                <select
                  className='form-select form-select-solid form-select-lg'
                  {...formik.getFieldProps('bank_id')}
                >
                  <option value=''>Select bank</option>
                  {
                    list.map((item)=> {
                      return  <option value={Number(item.bank_id)}>
                      {item.name}
                    </option>
                    })
                  }
                </select>
                   {formik.touched.bank_id && formik.errors.bank_id && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.bank_id}</div>
                      </div>
                    )}
                </div>
              </div>

              <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                <label className="col-lg-4 fw-bold text-muted"> {intl.formatMessage({ id: "TABLE-TYPE" })}</label>
                <div className="col-lg-8">
                <select
                  className='form-select form-select-solid form-select-lg'
                  {...formik.getFieldProps('type')}
                >
                  <option value={1}>
                      Admin
                    </option>
                    <option value={2}>
                      Super Admin
                    </option>
                </select>
                   {formik.touched.type && formik.errors.type && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.type}</div>
                      </div>
                    )}
                </div>
              </div>
             
              
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='submit' className='btn btn-primary' >
                  {intl.formatMessage({ id: "OTHER-SAVE-CHANGE" })}
                </button>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AddAdmin };

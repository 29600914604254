// Export settings
export const ExcelSettings = [
    {
      name: "Operator ID",
      key: "operator_id",
    },
    {
      name: "Name",
      key: "name",
    },
    {
      name: "Phone",
      key: "phone",
    },
    {
      name: "Email",
      key: "email",
    },
    {
      name: "Bank ID",
      key: "bank_id",
    },
    {
      name: "type",
      key: "type",
    },
    {
      name: "Country",
      key: "country",
    },
    {
      name: "Bank Name",
      key: "bank_name",
    },
    {
      name: "Bank Address",
      key: "bank_address",
    },
    {
      name: "Bank Provice",
      key: "bank_kecamatan",
    },
    {
      name: "Bank City",
      key: "bank_kota",
    },
    {
      name: "Bank Village",
      key: "bank_kelurahan",
    },
    {
      name: "Bank Phone",
      key: "bank_phone",
    },
    {
      name: "Bank Email",
      key: "bank_email",
    },
    {
      name: "Vision",
      key: "visi",
    },
    {
      name: "Mission",
      key: "misi",
    },
  ]

// Export settings
export const ExcelSettings = [
    {
      name: "Item Path",
      key: "image_path",
    },
    {
      name: "Item ID",
      key: "item_id",
    },
    {
      name: "Item Name",
      key: "item_name",
    },
    {
      name: "Weight",
      key: "weight",
    },
    {
      name: "Price",
      key: "price",
    },
    {
      name: "Per KG",
      key: "perkg",
    },
    {
      name: "Total",
      key: "total",
    },
    {
      name: "Description",
      key: "description",
    },
    {
      name: "Status",
      key: "status",
    },
  ]

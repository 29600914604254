// Export settings
export const ExcelSettings = [
    {
      name: "Item Code",
      key: "item_code",
    },
    {
      name: "Category",
      key: "category",
    },
    {
      name: "Name",
      key: "name",
    },
    {
      name: "Description",
      key: "description",
    },
    {
      name: "Image Path",
      key: "image_path",
    },
    {
      name: "Per Kg",
      key: "perkg",
    },
    {
      name: "Country",
      key: "country",
    },
  ]

import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from 'react-router-dom';
import { PageTitle } from "../../../../_metronic/layout/core";
import { TableSalesBOKG } from "./TableSalesBOKG";

const SalesBOKG = () => {
  const intl = useIntl();
  let authData : any = localStorage.getItem('binbank-auth');
  const navigate = useNavigate();

  useEffect(() => {
    if(JSON.parse(authData).email === "adminboi@gmail.com") {
      return navigate('error/404')
    }
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU-DATA.SALESBOKG" })}
      </PageTitle>
      <TableSalesBOKG />
    </>
  );
};
export { SalesBOKG };

// Export settings
export const ExcelSettings = [
    {
      name: "Date",
      key: "date",
    },
    {
      name: "Transaction ID",
      key: "transaction_id",
    },
    {
      name: "Request ID",
      key: "request_id",
    },
    {
      name: "Bank",
      key: "bank_name",
    },
    {
      name: "Operator",
      key: "operator_name",
    },
    {
      name: "Status",
      key: "status",
    },
    {
      name: "Total",
      key: "total",
    },
 
    {
      name: "Longitude",
      key: "longitude",
    },
    {
      name: "Latitude",
      key: "latitude",
    },
    {
      name: "Address",
      key: "address",
    },
    {
      name: "Notes",
      key: "notes",
    },
  ]

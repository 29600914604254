export interface IEditCustomer {
    name: string
    lastname: string
    username: string
    address: string
    phone: string
    email: string
    birthdate: string
    gender: string
    bank_id: number | null
    nasabah_id: number
    country: string
    status: number
  }
  
  export const stateEditCustomer: IEditCustomer = {
    name: '',
    lastname:'',
    username:'',
    address:'',
    phone:'',
    email:'',
    birthdate: '',
    gender:'',
    bank_id: null,
    nasabah_id: 0,
    country: 'PH',
    status: 0,
  }
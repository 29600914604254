/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";

import axios from "axios";
import DataTable, { TableColumn } from "react-data-table-component";
import { KTIcon } from "src/_metronic/helpers";
import { exportToExcel } from "src/app/helpers/utils";
import { ITonaseResponse } from "src/types/ITonaseResponse";
import { ExcelSettings } from "./ExcelSettings";

const TonaseWrapper: FC = () => {
  const intl = useIntl();
  const [list, setList] = useState<ITonaseResponse[]>([])
  const [searchTerm, setSearchTerm] = useState("");

  const COLUMNS: TableColumn<ITonaseResponse>[] = [
    {
      name: "ID",
      sortable: true,
      width: "80px",
      selector: (item) => item.item_id,
      cell: (item) => <div>{item.item_id}</div>,
    },
    {
      name: intl.formatMessage({ id: "TABLE-ITEM-IMAGE" }),
      sortable: false,
      width: "",
      cell: (item) => <div style={{width: 100, height: 100}}><img src={item.image_path} style={{objectFit: 'cover', width: 100, height: 100}}/></div>,
    },
    {
      name:  intl.formatMessage({ id: "TABLE-NAME" }),
      sortable: true,
      width: "",
      selector: (item) => item.name,
      cell: (item) =>  <a href={`/tonase/item/${item.item_id}`}>{item.name}</a>,
    },
    {
      name: intl.formatMessage({ id: "TABLE-DESCRIPTION" }),
      width: "",
      sortable: false,
      cell: (item) => item.description,
    },
    {
      name: intl.formatMessage({ id: "TABLE-PRICE" }),
      sortable: true,
      width: "",
      selector: (item) => item.price,
      cell: (item) => item.price,
    },
    {
      name: intl.formatMessage({ id: "TABLE-PRICE-PER-KG" }),
      sortable: true,
      width: "",
      selector: (item) => item.perkg,
      cell: (item) => item.perkg,
    },
    {
      name:  intl.formatMessage({ id: "TABLE-BANK" }),
      sortable: true,
      width: "",
      selector: (item) => item.bank_name,
      cell: (item) => <a href={`/bank/${item.bank_id}`}>{item.bank_name}</a>,
    },
    {
      name: intl.formatMessage({ id: "TABLE-WEIGHT" }),
      sortable: true,
      width: "",
      selector: (item) => item.weight,
      cell: (item) => item.weight,
    },
    
  ];

  const fetchList =  async () => {
    try {
      const  response = await axios.post(`${process.env.REACT_APP_API_URL}data/dashboard/get-tonage`, {
        country: "PH",
      })
      setList(response?.data?.data)
    } catch (e) {
      setList([])
    }
  }

  useEffect(()=> {
    fetchList()
  }, [])


  const filteredList = list.filter((item) =>
  Object.values(item).some(
    (value) =>
      value &&
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
  )
);

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU-TONASE" })}
      </PageTitle>
      <div className="card">
        <div className="card-header border-0 pt-5">
          <div className="d-flex  align-items-center gap-2">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">
                {intl.formatMessage({ id: "MENU-TONASE" })}
              </span>
            </h3>
            <div>
              <button type='button' className='btn btn-sm btn-light-primary' onClick={() =>  exportToExcel(list, ExcelSettings, 'Tonnage')}>
                <KTIcon iconName='exit-up' className='fs-2' />
                Export
              </button>
            </div>
          </div>
          <div
            className="card-toolbar"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-trigger="hover"
            title="Click to add a user"
          >
            <input
              id="search"
              className="form-control form-control-solid w-250px"
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <div className="card-body py-3">
          {
            list.length> 0  && <DataTable columns={COLUMNS} data={filteredList} pagination  />
          }
        </div>
      </div>
    </>
  );
};

export { TonaseWrapper };

import { encode as base64_encode } from 'base-64'
import { getNow } from '../../../helpers/utils'
import { storeErrorLogs } from '../../../modules/logs/core/_requests'
import SweetAlert from '../../libs/SweetAlert'
import { AuthModel } from './_models'
import { logout, refresh } from './_requests'

const AUTH_LOCAL_STORAGE_KEY = 'binbank-auth'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = async () => {
  if (!localStorage) {
    return
  }

  try {
    const auth = getAuth()

    if (!auth || !auth.refresh_token) {
      return
    }

    const response = await logout(auth.refresh_token)

    if (!response || !response.data || !response.data.message) {
      return
    }

    SweetAlert.success(response.data.message, '')
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)

  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

const getParamsFromUrl = (url: string) => {
  const params: any = {}
  const urlParts = url.split('?')
  if (urlParts.length > 1) {
    const query = urlParts[1]
    const vars = query.split('&')
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=')
      params[pair[0]] = decodeURIComponent(pair[1])
    }
  }
  return params
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'

  // Request interceptor for API calls
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string; 'Request-Timestamp': string}}) => {

      config.headers['Request-Timestamp'] = getNow()

      const auth = getAuth()
      if (auth && auth.access_token) {
        config.headers.Authorization = `Bearer ${auth.access_token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )

  // Response interceptor for API calls
  axios.interceptors.response.use(
    (response: any) => {

      // Send (request, params, response) when status code is 200
      if (response.status === 200) {

        // Encode base64 
        const params = JSON.stringify(response.config.params) || JSON.stringify(getParamsFromUrl(response.config.url))
        const paramsBase64 = base64_encode(params)

        const data = JSON.stringify(response.data)
        const dataBase64 = base64_encode(data)

        // storeHitApiLogs(
        //   response.data.message || `Success ${response.config.method} ${response.config.url}`,
        //   response.config.url,
        //   paramsBase64,
        //   dataBase64,
        //   response.config.headers['Request-Timestamp'],
        //   getNow()
        // )
      }

      return response
    },
    async (error: any) => {
      const originalRequest = error.config

      // Send error when status code is 500
      if (error.response.status === 500 && !originalRequest._retry) {
        originalRequest._retry = true
        
        const response = await storeErrorLogs(error.response.data.message)

        if (!response) {
          return Promise.reject(error)
        }

        return Promise.reject(error)

      }

      // Refresh token
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true
        const auth = getAuth()

        if (!auth || !auth.refresh_token) {
          return Promise.reject(error)
        }

        const response = await refresh(auth.refresh_token)

        if (!response || !response.data || !response.data.access_token) {
          return Promise.reject(error)
        }
        
        auth.access_token = response.data.access_token
        
        setAuth(auth)
        axios.defaults.headers.common.Authorization = `Bearer ${auth.access_token}`

        SweetAlert.error('Expired Token', '')

        return axios(originalRequest)
      }
      return Promise.reject(error)
    }
  )

}

export { AUTH_LOCAL_STORAGE_KEY, getAuth, removeAuth, setAuth }


import React from "react";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import TablePagination from "./TablePagination";
import { useIntl } from "react-intl";
import moment from "moment";

export default function Table({ infoDate, infoStartTime, infoEndTime, header, rowData, meta, onPageChange, onSort, sort, sort_by, loading }) {
  const intl = useIntl();
  const formatedStartDate = moment(infoDate[0].startDate).locale(intl.locale).format('ddd, DD MMM YYYY');
  const formatedEndDate = moment(infoDate[0].endDate).locale(intl.locale).format('ddd, DD MMM YYYY');

  return (
    <>
      <div className="alert alert-primary m-3">
        <div className="alert-text font-weight-bold">
        <strong> {intl.formatMessage({ id: "TABLE.INFO.SELECTED" })}:</strong> {formatedStartDate} {infoStartTime} - {" "}
          {formatedEndDate} {infoEndTime} | <strong>{intl.formatMessage({ id: "TABLE.INFO.TOTALDATA" })}:</strong> {meta?.total} | <strong>{intl.formatMessage({ id: "TABLE.INFO.SORTINGBY" })} :</strong> {sort_by} ({sort})
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
          <TableHeader header={header} onSort={onSort} sort={sort} sort_by={sort_by}/>
          <TableBody header={header} rowData={rowData} loading={loading}/>
        </table>
      </div>
      <TablePagination
        page={meta?.page}
        limit={meta?.limit}
        total={meta?.total}
        last_page={meta?.last_page}
        onPageChange={onPageChange}
      />
    </>
  );
}

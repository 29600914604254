/* eslint-disable react/function-component-definition */
// LoadingProvider.tsx
import React, { ReactNode } from 'react';
import { IStore, useStore } from './store';

interface ConfigProviderProps {
  children: ReactNode;
}

const StoreContext = React.createContext<IStore | undefined>(undefined);

const StoreProvider: React.FC<ConfigProviderProps> = ({ children }: any) => {
  const Store = useStore();

  return (
    <StoreContext.Provider value={Store}>
      {children}
    </StoreContext.Provider>
  );
};

const useStoreContext = () => {
  const context = React.useContext(StoreContext);
  if (!context) {
    throw new Error('useStoreContext must be used within a StoreProvider');
  }
  return context;
};

export { StoreProvider, useStoreContext };

/* eslint-disable react-hooks/exhaustive-deps */

const SelectRowData = ({ onChange, defaultValue }) => {
    return (
      <select
        className="form-select "
        data-kt-select2="true"
        data-placeholder="Select option"
        data-allow-clear="true"
        defaultValue={defaultValue}
        onChange={onChange}
      >
        <option value={10}>10</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </select>
    );
  };
  
  export { SelectRowData };
  
export interface IAddBank {
  bank_name: string;
  bank_address: string;
  bank_kota: string;
  bank_kelurahan: string;
  bank_kecamatan: string;
  bank_email: string;
  bank_phone: string;
  latitude: string;
  longitude: string;
  country: string;
  name: string;
  phone: string;
  email: string;
  password: string;
}

export const stateAddBank: IAddBank = {
  name: "",
  phone: "",
  email: "",
  password: "",
  bank_name: "",
  bank_address: "",
  bank_kota: "",
  bank_kelurahan: "",
  bank_kecamatan: "",
  bank_email: "",
  bank_phone: "",
  latitude: "",
  longitude: "",
  country: "PH",
};

// Export settings
export const ExcelSettings = [
    {
      name: "Date",
      key: "date",
    },
    {
      name: "Type of Waste",
      key: "jenis_sampah",
    },
    {
      name: "Description",
      key: "description",
    },
    {
      name: "Name",
      key: "name",
    },
    {
      name: "Admin Name",
      key: "nama_admin",
    },
    {
      name: "Customer Name",
      key: "nasabah_name",
    },
    {
      name: "Gender",
      key: "gender",
    },
    {
      name: "Birthdate",
      key: "birthdate",
    },
  
    {
      name: "Weight",
      key: "weight",
    },
    {
      name: "Price",
      key: "price",
    },
    {
      name: "total",
      key: "total",
    },
    
  ]

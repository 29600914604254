
export const sidebar = [
    {
      id: 1,
      name: "MENU-DASHBOARD",
      pathname: "/dashboard",
      fontIcon: "bi-app-indicator",
      icon: "element-11",
      children: [],
    },
    {
      id: 1,
      name: "MENU-CUSTOMER",
      pathname: "/customers",
      fontIcon: "bi-app-indicator",
      icon: "element-11",
      children: [],
    },
    {
      id: 2,
      name: "MENU-TONASE",
      pathname: "/tonase",
      fontIcon: "bi-app-indicator",
      icon: "element-11",
      children: [],
    },
    {
      id: 3,
      name: "MENU-DEFAULT-ITEM",
      pathname: "/default-item",
      fontIcon: "bi-app-indicator",
      icon: "element-11",
      children: [],
    },
    {
      id: 4,
      name: "MENU-BANK",
      pathname: "/bank",
      fontIcon: "bi-app-indicator",
      icon: "element-11",
      children: [],
    },
    {
      id: 5,
      name: "MENU-ADMIN",
      pathname: "/admin",
      fontIcon: "bi-app-indicator",
      icon: "element-11",
      children: [],
    },
    {
      id: 6,
      name: "MENU-TRANSACTION",
      pathname: "/transaction",
      fontIcon: "bi-app-indicator",
      icon: "element-11",
      children: [],
    },
    {
      id: 7,
      name: "MENU-CRITISM-SUGGESTION",
      pathname: "/critism-suggestion",
      fontIcon: "bi-app-indicator",
      icon: "element-11",
      children: [],
    },
  ];
  
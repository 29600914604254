/* eslint-disable jsx-a11y/anchor-is-valid */

import axios from "axios";
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom"; // Import useParams
import LatestRequest from "src/app/modules/latest-request/LatestRequest";
import LatestTransaction from "src/app/modules/latest-transaction/LatestTransaction";
import Nasabah from "src/app/modules/nasabah/Nasabah";
import Operator from "src/app/modules/operator/Operator";
import { IBankDetail } from "./model/IBankDetail";

const BankDetail: FC = () => {
  const intl = useIntl();
  const { id } = useParams();
  const [detail, setDetail] = useState<IBankDetail>();
  const [operator, setOperator]= useState([]);

  const fetchDetail = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}bank/detail`,
        {
          id: id,
        }
      );
      setDetail(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchOperator =  async () => {
    try {
      const  response = await axios.post(`${process.env.REACT_APP_API_URL}data/dashboard/get-operator`,  {
        bank_id: Number(id),
        "country": "PH"
      })
      setOperator(response?.data?.data)
    } catch (e) {
    }
  }

  useEffect(() => {
    fetchDetail();
    fetchOperator()
  }, []);


  return (
    <>
      <div className="row g-5 g-xl-8">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  Request
                </span>
              </h3>
            </div>
            <div className="card-body py-3">
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">ID</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {detail?.bank?.bank_id}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Name</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {detail?.bank?.name}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Address</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {detail?.bank?.address}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">City</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {detail?.bank?.kota}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Village</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {detail?.bank?.kelurahan}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">District</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {detail?.bank?.kecamatan}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Phone</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {detail?.bank?.phone}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Email</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {detail?.bank?.email}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Vision</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {detail?.bank?.visi}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Mission</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {detail?.bank?.misi}
                  </span>
                </div>
              </div>



              
            </div>
          </div>
        </div>
        <Operator transaction={operator}/>
        <Nasabah transaction={detail?.bank_nasabah} />
        <LatestRequest transaction={detail?.bank_request} bank={true}/>
        <LatestTransaction transaction={detail?.bank_transaction} bank={true}/>
        {/* <DetailRequest transaction={detail?.request_detail}/> */}
        {/* <LatestTransaction transaction={detail?.request_transaction}/> */}
        {/* <LatestRequest transaction={detail?.nasabah_request}/>
        <LatestTransaction transaction={detail?.nasabah_transaction}/> */}
      </div>
    </>
  );
};

export { BankDetail };

import axios from 'axios'
import { AuthModel, MessageModel, UserModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}login/dashboard`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

export const LOGIN_HOST = `${API_URL}auth/login`
export const GET_USER_BY_ACCESSTOKEN_HOST = `${API_URL}/auth/me`
export const REFRESH_TOKEN = `${API_URL}auth/refresh`
export const LOGOUT_HOST = `${API_URL}auth/logout`
export interface ITables {
  status:  boolean;
  message: string;
  data:    Data;
}

export interface Data {
  user_id:       number;
  user_name:     string;
  token:         string;
  country:       string;
  refresh_token: string;
}


// Server should return AuthModel
export function login(username: string, password: string, country: string) {
  return axios.post<ITables>(LOGIN_URL, {
    username,
    password,
    country,
  })
}

export function refresh(refresh_token: string) {
  return axios.post<AuthModel>(REFRESH_TOKEN, { refresh_token })
}

export function logout(refresh_token: string) {
  return axios.post<MessageModel>(LOGOUT_HOST, { refresh_token })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken() {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_HOST)
}

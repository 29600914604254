export const HeaderSalesTableList = [
  {
    id: 0,
    name: "No",
    key: "no",
    sort: false,
    type: 'number',
  },
  {
    id: 1,
    name: "Transaction ID",
    key: "transaction_id",
    sort: true,
    type: 'text',
  },
  {
    id: 2,
    name: "Item Code",
    key: "item_code",
    sort: true,
    type: 'text',
  },
  {
    id: 3,
    name: "Name",
    key: "name",
    sort: true,
    type: 'text',
  },
  {
    id: 4,
    name: "QTY",
    key: "qty",
    sort: true,
    type: 'number',
  },
  {
    id: 6,
    name: "Category",
    key: "category",
    sort: true,
    type: 'text',
  },
  {
    id: 7,
    name: "Departement",
    key: "department",
    sort: true,
    type: 'text',
  },
  {
    id: 8,
    name: "Order Time",
    key: "order_time",
    sort: true,
    type: 'date',
  },
  {
    id: 9,
    name: "Unit Price",
    key: "unit_price",
    sort: true,
    type: 'price',
  },
  {
    id: 10,
    name: "Discount",
    key: "discount",
    sort: true,
    type: 'price',
  },
  {
    id: 11,
    name: "Discount Name",
    key: "discount_name",
    sort: true,
    type: 'text',
  },
  {
    id: 12,
    name: "Service Charge",
    key: "service_charge",
    sort: true,
    type: 'price',
  },
  {
    id: 13,
    name: "TAX",
    key: "tax",
    sort: true,
    type: 'price',
  },
  {
    id: 14,
    name: "Invoice ID",
    key: "invoice_id",
    sort: true,
    type: 'text',
  },
  {
    id: 15,
    name: "Store Code",
    key: "store_code",
    sort: true,
    type: 'text',
  },
  {
    id: 16,
    name: "Open Time",
    key: "open_time",
    sort: true,
    type: 'date',
  },
  {
    id: 17,
    name: "Close Time",
    key: "close_time",
    sort: true,
    type: 'date',
  },
  {
    id: 18,
    name: "Table Name",
    key: "table_name",
    sort: true,
    type: 'text',
  },
  {
    id: 19,
    name: "Payment Name",
    key: "payment_name",
    sort: true,
    type: 'text',
  },
];

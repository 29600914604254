/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTCard, KTIcon } from "../../../../_metronic/helpers";
import Table from "../../../../_metronic/layout/components/table/Table";
import { HeaderSalesTableList } from "../../../../constant/HeaderSalesTableList";
import { RangeDatePicker } from "../../../../_metronic/layout/components/range-datepicker/RangeDatePicker";
import { Search } from "../../../../_metronic/layout/components/search/Search";
import { SelectRowData } from "../../../../_metronic/layout/components/select-row-data/SelectRowData";
import { exportData } from "src/app/helpers/utils";
import { useServices } from "../useService";
import { useIntl } from "react-intl";

const REPORT_URL = `${process.env.REACT_APP_API_URL}/report/KLP.GADING`;
const EXPORT_URL = `${process.env.REACT_APP_API_URL}/report/export/KLP.GADING`;
const TableSalesBOKG: React.FC = () => {
  const intl = useIntl();
  const {
    filter,
    setFilter,
    reportData,
    onPageChange,
    dateRange,
    handleDateRangeChange,
    handleTimeStart,
    handleTimeEnd,
    onSort,
    tableLoading,
  } = useServices(REPORT_URL);
  return (
    <KTCard>
      <div className="card-header border-0 pt-5">
      <div className="card-toolbar gap-3 d-flex justify-content-between w-100">
          <Search
            onChange={(e) =>
              setFilter({ ...filter, search_text: e.target.value })
            }
            value={filter?.search_text}
            placeholder={intl.formatMessage({ id: "OTHER.SEARCH" })}
          />
          <div className="d-flex flex-wrap ">
            <div>
              <SelectRowData
                onChange={(e) =>
                  setFilter({ ...filter, limit: Number(e.target.value) })
                }
                defaultValue={filter.limit}
              />
            </div>
            <div>
              <RangeDatePicker
                handleDateRangeChange={handleDateRangeChange}
                dateRange={dateRange}
                timeStart={filter.start_time}
                timeEnd={filter.end_time}
                handleTimeStart={handleTimeStart}
                handleTimeEnd={handleTimeEnd}
              />
            </div>
            <div>
              <button
                type="button"
                className="btn btn-light-primary text-nowrap w-100 d-flex align-items-center"
                onClick={() => exportData(EXPORT_URL, filter)}
              >
                <KTIcon iconName="exit-up" className="fs-2" />
                {intl.formatMessage({ id: "OTHER.EXPORT" })}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card-body py-3">
        <Table
          loading={tableLoading}
          infoDate={dateRange}
          infoStartTime={filter.start_time}
          infoEndTime={filter.end_time}
          sort={filter?.sort}
          sort_by={filter?.sort_by}
          onSort={(key) => onSort(key)}
          header={HeaderSalesTableList}
          rowData={reportData?.data}
          meta={reportData?.meta}
          onPageChange={(e) => onPageChange(e)}
        />
      </div>
    </KTCard>
  );
};

export { TableSalesBOKG };

/* eslint-disable jsx-a11y/anchor-is-valid */

import axios from "axios";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom"; // Import useParams
import { IDRFormat } from "src/app/helpers/utils";
import DetailRequest from "src/app/modules/detail/DetailRequest";
import LatestTransaction from "src/app/modules/latest-transaction/LatestTransaction";
import { IDetailRequest } from "./model/IDetailRequest";

const RequestDetail: FC = () => {
  const intl = useIntl();
  const { id } = useParams();
  const [detail, setDetail] = useState<IDetailRequest>();

  const fetchDetail = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}request/detail`,
        {
          id: id,
        }
      );
      setDetail(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchDetail();
  }, []);

  return (
    <>
      <div className="row g-5 g-xl-8">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  Request
                </span>
              </h3>
            </div>
            <div className="card-body py-3">
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">ID</label>

                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {detail?.request?.[0].nasabah_id}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Date</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {moment(detail?.request?.[0].date).format('DD/MM/YYYY')}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Customer</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    <a href={`/customers/${detail?.request?.[0].nasabah_id}`}>{detail?.request?.[0].nasabah_name}</a>
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Bank</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                   <a href={`/bank/${detail?.request?.[0].bank_id}`}>{detail?.request?.[0].bank_name}</a>
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Total</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                   {IDRFormat(detail?.request?.[0].total)}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Longitude</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                   {(detail?.request?.[0].longitude)}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Latitude</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                   {(detail?.request?.[0].latitude)}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Address</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                   {(detail?.request?.[0].address)}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Notes</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                   {(detail?.request?.[0].notes)}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Status</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                   {(detail?.request?.[0].status)}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Arrival Time</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                   {moment(detail?.request?.[0].arrival_time).format('DD/MM/YYYY')}
                  </span>
                </div>
              </div>



              
            </div>
          </div>
        </div>
        <DetailRequest transaction={detail?.request_detail}/>
        <LatestTransaction transaction={detail?.request_transaction}/>
        {/* <LatestRequest transaction={detail?.nasabah_request}/>
        <LatestTransaction transaction={detail?.nasabah_transaction}/> */}
      </div>
    </>
  );
};

export { RequestDetail };

/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useStore } from "src/app/store/store";
import { ICityResponse } from "src/types/ICityResponse";
import { IProviceResponse } from "src/types/IProviceResponse";
import { IVillageResponse } from "src/types/IVillageResponse";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { KTIcon } from "../../../helpers";
import { IEditBank, stateEditBank } from "./model/IEditBank";

const EditBank: FC = () => {
  const intl = useIntl();
  const {editBank} = useStore()
  const addAdminFormValidationSchema = Yup.object().shape({
    name: Yup.string().required("Bank Name is required"),
    phone: Yup.string().required("Phone is required"),
    kecamatan: Yup.string().required(
      `${intl.formatMessage({ id: "TABLE-BANK-KECAMATAN" })} is required`
    ),
    kota: Yup.string().required(
      `${intl.formatMessage({ id: "TABLE-BANK-KOTA" })} is required`
    ),
    kelurahan: Yup.string().required(
      `${intl.formatMessage({ id: "TABLE-BANK-KELURAHAN" })} is required`
    ),
    address: Yup.string().required("Bank Adress is required"),
    latitude: Yup.string().required("Latitude is required"),
    longitude: Yup.string().required("Longitude is required"),
  });

  const fetchDetail = async () => {
    await formik.setFieldValue('name', editBank.name);
    await formik.setFieldValue('phone', editBank.phone);
    await formik.setFieldValue('kelurahan', editBank.kelurahan);
    await formik.setFieldValue('kota', editBank.kota);
    await formik.setFieldValue('kecamatan', editBank.kecamatan);
    await formik.setFieldValue('address', editBank.address);
    await formik.setFieldValue('visi', editBank.visi);
    await formik.setFieldValue('misi', editBank.misi);
    await formik.setFieldValue('bank_id', editBank.bank_id);
    await formik.setFieldValue('latitude', editBank.latitude);
    await formik.setFieldValue('longitude', editBank.longitude);
  }
  useEffect(()=> {
    fetchDetail()
  }, [editBank])

  const [province, setProvice] = useState<IProviceResponse[]>([]);
  const [city, setCity] = useState<ICityResponse[]>([]);
  const [village, setVillage] = useState<IVillageResponse[]>([]);
  const formik = useFormik<IEditBank>({
    initialValues: {
      ...stateEditBank,
    },
    validationSchema: addAdminFormValidationSchema,
    onSubmit: (values) => {
      console.log(values)
      // createData(values);
    },
  });
  const createData = async (values: IEditBank) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}data/dashboard/edit-bank`,
        values
      );
      if (response.status === 200 || response.status === 201) {
        Swal.fire({
          title: "Success!",
          text: "Success",
          icon: "success",
          confirmButtonText: "OK",
        }).then((response) => {
          if (response.isConfirmed) {
            window.location.reload();
          }
        });
      }
    } catch (e: any) {
      Swal.fire({
        title: "Error!",
        text: e?.response?.data?.msg,
        icon: "error",
      });
    }
  };

  const fetchProv = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}ph/prov`
      );
      setProvice(response?.data?.data);
    } catch (e) {
      setProvice([]);
    }
  };

  const fetchCity = async () => {
    try {
      const find = province.find((item)=> item.provDesc === formik.values.kecamatan)
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}ph/prov/city/${find?.provCode}`
      );
      setCity(response?.data?.data);
    } catch (e) {
      setCity([]);
    }
  };

  const fetchVillage = async () => {
    try {
      const find = city.find((item)=> item.citymunDesc === formik.values.kota)
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}ph/city/barangay/${find?.citymunCode}`
      );
      setVillage(response?.data?.data);
    } catch (e) {
      console.log(e)
      setVillage([]);
    }
  };

  useEffect(() => {
    formik.setFieldValue("kota", "");
    formik.setFieldValue("kelurahan", "");
    fetchCity();
  }, [formik.values.kecamatan]);

  useEffect(() => {
    formik.setFieldValue("kelurahan", "");
    fetchVillage();
  }, [formik.values.kota]);

  useEffect(() => {
    fetchProv();
  }, []);

  // console.log("formik", formik.values)


  return (
    <div className="modal fade" id="kt_modal_edit_bank" aria-hidden="true">
      <div className="modal-dialog mw-650px">
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <KTIcon iconName="cross" className="fs-1" />
            </div>
          </div>

          <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
            <div className="text-center">
              <h1 className=""> {intl.formatMessage({ id: "MENU-BANK" })}</h1>
            </div>

            <div className="card-body p-9">
            
              <form onSubmit={formik.handleSubmit} noValidate className="form">
                <div className="notice d-flex bg-light-info rounded border-info border border-dashed p-3 mb-7">
                  <div className="d-flex flex-stack flex-grow-1">
                    <div className="fw-bold">
                      <h4 className="text-gray-800 fw-bolder">Bank</h4>
                      <div className="fs-6 text-gray-600">
                        Information for Bank
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                  <label className="col-lg-4 fw-bold text-muted">
                    Bank Name
                  </label>
                  <div className="col-lg-8">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="Bank Name"
                      {...formik.getFieldProps("name")}
                    />
                    {formik.errors.name && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.name}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                
                <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                  <label className="col-lg-4 fw-bold text-muted">
                    Bank Phone
                  </label>
                  <div className="col-lg-8">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="Bank Phone"
                      {...formik.getFieldProps("phone")}
                    />
                    { formik.errors.phone && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.phone}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                  <label className="col-lg-4 fw-bold text-muted">
                    {" "}
                    {intl.formatMessage({ id: "TABLE-BANK-KECAMATAN" })}
                  </label>
                  <div className="col-lg-8">
                    <select
                      className="form-select form-select-solid form-select-lg"
                      {...formik.getFieldProps("kecamatan")}
                    >
                      <option value="">
                        {intl.formatMessage({ id: "TABLE-BANK-KECAMATAN" })}
                      </option>
                      {province.map((item) => {
                        return (
                          <option value={item.provDesc}>{item.provDesc}</option>
                        );
                      })}
                    </select>
                    {
                      formik.errors.kecamatan && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.kecamatan}
                          </div>
                        </div>
                      )}
                  </div>
                </div>

                <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                  <label className="col-lg-4 fw-bold text-muted">
                    {" "}
                    {intl.formatMessage({ id: "TABLE-BANK-KOTA" })}
                  </label>
                  <div className="col-lg-8">
                    <select
                      className="form-select form-select-solid form-select-lg"
                      {...formik.getFieldProps("kota")}
                    >
                      <option value="">
                        {intl.formatMessage({ id: "TABLE-BANK-KOTA" })}
                      </option>
                      {city.map((item) => {
                        return (
                          <option value={item.citymunDesc}>
                            {item.citymunDesc}
                          </option>
                        );
                      })}
                    </select>
                    {formik.errors.kota && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.kota}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                  <label className="col-lg-4 fw-bold text-muted">
                    {" "}
                    {intl.formatMessage({ id: "TABLE-BANK-KELURAHAN" })}
                  </label>
                  <div className="col-lg-8">
                    <select
                      className="form-select form-select-solid form-select-lg"
                      {...formik.getFieldProps("kelurahan")}
                    >
                      <option value="">
                        {intl.formatMessage({ id: "TABLE-BANK-KELURAHAN" })}
                      </option>
                      {village.map((item) => {
                        return (
                          <option value={item.brgyDesc}>
                            {item.brgyDesc}
                          </option>
                        );
                      })}
                    </select>
                    { formik.errors.kelurahan && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.kelurahan}
                        </div>
                      </div>
                    )}
                  </div>
                </div>


                <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                  <label className="col-lg-4 fw-bold text-muted">
                    Bank Address
                  </label>
                  <div className="col-lg-8">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="Bank Editress"
                      {...formik.getFieldProps("address")}
                    />
                    { formik.errors.address && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.address}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                  <label className="col-lg-4 fw-bold text-muted">
                    Bank Latitude
                  </label>
                  <div className="col-lg-8">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="Bank Latitude"
                      {...formik.getFieldProps("latitude")}
                    />
                    {formik.errors.latitude && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.latitude}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                  <label className="col-lg-4 fw-bold text-muted">
                    Bank Longitude
                  </label>
                  <div className="col-lg-8">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="Bank Longitude"
                      {...formik.getFieldProps("longitude")}
                    />
                    {formik.errors.longitude && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.longitude}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                  <label className="col-lg-4 fw-bold text-muted">
                   Vision
                  </label>
                  <div className="col-lg-8">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="Vision"
                      {...formik.getFieldProps("visi")}
                    />
                    {formik.errors.visi && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.visi}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mb-7 d-flex flex-row justify-content-between align-items-center">
                  <label className="col-lg-4 fw-bold text-muted">
                   Mission
                  </label>
                  <div className="col-lg-8">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="Mission"
                      {...formik.getFieldProps("misi")}
                    />
                    {formik.errors.misi && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.misi}
                        </div>
                      </div>
                    )}
                  </div>
                </div>


               

                <div className="card-footer d-flex justify-content-end py-6 px-9">
                  <button type="submit" className="btn btn-primary" onClick={() => createData(formik.values)}>
                    {intl.formatMessage({ id: "OTHER-SAVE-CHANGE" })}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { EditBank };

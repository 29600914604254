export interface IEditAdmin {
    name: string
    phone: string
    email: string
    password: string
    type: number
    bank_id: number
    operator_id: number
  }
  
  export const stateEditAdmin: IEditAdmin = {
    name: '',
    phone:'',
    email:'',
    password: '',
    bank_id: 0,
    type: 1,
    operator_id: 0
  }
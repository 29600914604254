import {FC, useEffect, useState} from 'react'
import {useLang} from './Metronici18n'
import {IntlProvider} from 'react-intl'

import {WithChildren} from '../helpers'

const I18nProvider: FC<WithChildren> = ({children}) => {
  const locale = useLang()
  const [lang, setLang] = useState('');

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('/language.json');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setLang(data)
      } catch (error) {
        console.error('Error fetching JSON data language:', error);
      }
    }

    fetchData();
  }, []);

  return (
    <IntlProvider locale={locale} messages={lang[locale]}>
      {children}
    </IntlProvider>
  )
}

export {I18nProvider}

/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";

import axios from "axios";
import DataTable, { TableColumn } from "react-data-table-component";
import { KTIcon } from "src/_metronic/helpers";
import { exportToExcel } from "src/app/helpers/utils";
import { useStore } from "src/app/store/store";
import { IAdminResponse } from "src/types/IAdminResponse";
import Swal from "sweetalert2";
import { ExcelSettings } from "./ExcelSettings";

const AdminWrapper: FC = () => {
  const intl = useIntl();
  const [list, setList] = useState<IAdminResponse[]>([])
  const [searchTerm, setSearchTerm] = useState("");
  const {setEditAdmin} = useStore()
  const COLUMNS: TableColumn<IAdminResponse>[] = [
    {
      name: "Action",
      sortable: false,
      width: "150px",
      cell: (item) => (
        <div className="d-flex  align-items-center" style={{ width: 300 }}>
          <div
            onClick={() => setEditAdmin(item)}
            className=" badge"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_edit_admin"
            style={{cursor: 'pointer'}}
          >
            <KTIcon iconName="pencil" className="fs-3" />
          </div>
          <div
            onClick={() =>
              Swal.fire({
                title: "Are you sure?",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Yes, delete",
                denyButtonText: `Cancel`,
              }).then((result) => {
                if (result.isConfirmed) {
                  fetchDelete(item.operator_id).then(
                    (res: any) => {
                      if (res?.status === 200 || res?.status === 201) {
                        Swal.fire("Deleted!", "", "success");
                        fetchList();
                      } else {
                        Swal.fire(res?.response?.data?.msg, "", "error");
                      }
                    }
                  );
                } else if (result.isDenied) {
                  Swal.fire("Changes are not saved", "", "info");
                }
              })
            }
            style={{ cursor: "pointer" }}
            className={`btn btn-sm badge badge-light-danger`}
          >
            {' Delete'}
          </div>
        </div>
      ),
    },
    {
      name: intl.formatMessage({ id: "TABLE-NAME" }),
      sortable: true,
      width: "",
      selector: (item) => item.name,
      cell: (item) => item.name,
    },
    {
      name: intl.formatMessage({ id: "TABLE-PHONE-NUMBER" }),
      sortable: true,
      width: "",
      selector: (item) => item.phone,
      cell: (item) => item.phone,
    },
    {
      name: intl.formatMessage({ id: "TABLE-EMAIL" }),
      sortable: true,
      width: "",
      selector: (item) => item.email,
      cell: (item) => item.email,
    },
    {
      name: intl.formatMessage({ id: "TABLE-BANK" }),
      width: "",
      sortable: true,
      selector: (item) => item.bank_name,
      cell:  (item) => <a href={`/bank/${item.bank_id}`}>{item.bank_name}</a>,
    },
    {
      name: intl.formatMessage({ id: "TABLE-ROLE" }),
      sortable: true,
      width: "",
      selector: (item) => item.type,
      cell: (item) => item.type === 0 ? 'In Active': item.type === 1 ? 'Admin' : 'Super Admin',
    },
  
  ];

  const fetchList =  async () => {
    try {
      const  response = await axios.post(`${process.env.REACT_APP_API_URL}profile/admin/all`, {
        country: "PH",
      })
      setList(response?.data?.data)
    } catch (e) {
      setList([])
    }
  }

  useEffect(()=> {
    fetchList()
  }, [])


  const filteredList = list.filter((item) =>
  Object.values(item).some(
    (value) =>
      value &&
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
  )
);


const fetchDelete = async (operator_id: number) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}data/dashboard/delete-operator`,
      {
        operator_id,
      }
    );
    return response;
  } catch (e) {
    return e;
  }
};
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU-ADMIN" })}
      </PageTitle>
      <div className="card">
        <div className="card-header border-0 pt-5">
          <div className="d-flex  align-items-center gap-2">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">
                {intl.formatMessage({ id: "MENU-ADMIN" })}
              </span>
            </h3>
            <div
              className="card-toolbar"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-bs-trigger="hover"
              title="Click to add a user"
            >
              <a
                href="#"
                className="btn btn-sm btn-light-primary"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_add_admin"
              >
                <KTIcon iconName="plus" className="fs-3" />
                {intl.formatMessage({ id: "MENU-ADMIN" })}
              </a>
            </div>
            <div>
              <button type='button' className='btn btn-sm btn-light-primary' onClick={() =>  exportToExcel(list, ExcelSettings, 'admin')}>
                <KTIcon iconName='exit-up' className='fs-2' />
                Export
              </button>
            </div>
          </div>
          <div
            className="card-toolbar"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-trigger="hover"
            title="Click to add a user"
          >
            <input
              id="search"
              className="form-control form-control-solid w-250px"
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <div className="card-body py-3">
          {
            list.length> 0  && <DataTable columns={COLUMNS} data={filteredList} pagination  />
          }
        </div>
      </div>
    </>
  );
};

export { AdminWrapper };

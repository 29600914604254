/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/prefer-default-export */
import { IAdminResponse } from 'src/types/IAdminResponse';
import { IBankResponse } from 'src/types/IBankResponse';
import { IDefaultItemResponse } from 'src/types/IDefaultItemResponse';
import { IListCustomerResponse } from 'src/types/IListCustomerResponse';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type IStore = {
  editBank: IBankResponse;
  editCustomer: IListCustomerResponse;
  editAdmin: IAdminResponse;
  editDefaultItem: IDefaultItemResponse;
  setEditAdmin: (editAdmin: IAdminResponse) => void;
  setEditBank: (editBank: IBankResponse) => void;
  setEditCustomer: (editCustomer: IListCustomerResponse) => void;
  setEditDefaultItem: (editDefaultItem: IDefaultItemResponse) => void;
};

const useStore = create(
  persist<IStore>(
    (set, get) => ({
      editBank: {
        bank_id: 0,
        name: '',
        address: '',
        kota: '',
        kelurahan: '',
        kecamatan: '',
        phone: '',
        email: '',
        visi: '',
        misi: '',
        latitude: '',
        longitude: '',
        status: 0,
        sales: undefined,
        transaction: undefined,
        country: '',
        sunday: 0,
        monday: 0,
        tuesday: 0,
        wednesday: 0,
        thursday: 0,
        friday: 0,
        saturday: 0,
        is_delivery: 0,
        start_hour: '',
        close_hour: '',
        delivery_sunday: 0,
        delivery_monday: 0,
        delivery_tuesday: 0,
        delivery_wednesday: 0,
        delivery_thursday: 0,
        delivery_friday: 0,
        delivery_saturday: 0,
        delivery_start_hour: '',
        delivery_close_hour: ''
      },
      editAdmin: {
        operator_id: 0,
        name: '',
        phone: '',
        email: '',
        bank_id: 0,
        type: 0,
        country: '',
        bank_name: '',
        bank_address: '',
        bank_kota: '',
        bank_kelurahan: '',
        bank_kecamatan: '',
        bank_phone: '',
        bank_email: '',
        visi: '',
        misi: ''
      },
      editCustomer: {
        nasabah_id: 0,
        name: '',
        lastname: '',
        address: '',
        phone: '',
        email: '',
        bank_id: null,
        status: 0,
        username: '',
        birthdate: '',
        gender: '',
        country: '',
        bank_list: ''
      },
      editDefaultItem: {
        image_path: '',
        item_code: '',
        category: '',
        name: '',
        description: '',
        perkg: 0,
        country: '',
        metric: ''
      },
      setEditBank:  (editBank: any) => set({ editBank }),
      setEditAdmin:  (editAdmin: any) => set({ editAdmin }),
      setEditCustomer: (editCustomer: any) => set({ editCustomer }),
      setEditDefaultItem: (editDefaultItem: any) => set({ editDefaultItem }),
    }),
    {
      name: 'state-store',
    },
  ),
);

export { useStore };

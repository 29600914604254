import { useIntl } from "react-intl";
import { StatisticsWidget } from "src/_metronic/partials/widgets";
export default function StatisticPage({data}:any) {
  console.log("data", data)
  const intl = useIntl();
  return (
    <div className="row g-5 g-xl-8">
      <div className="col-xl-4">
        <StatisticsWidget
          className="card-xl-stretch mb-xl-8"
          image="abstract-3.svg"
          title={intl.formatMessage({ id: "STATISTIC-CUSTOMER" })}
          value={data.total_nasabah}
        />
      </div>
      <div className="col-xl-4">
        <StatisticsWidget
          className="card-xl-stretch mb-xl-8"
          image="abstract-4.svg"
          title={intl.formatMessage({ id: "STATISTIC-TONASE" })}
          value={data.total_tonase}
        />
      </div>
      <div className="col-xl-4">
        <StatisticsWidget
          className="card-xl-stretch mb-xl-8"
          image="abstract-5.svg"
          title={intl.formatMessage({ id: "STATISTIC-TYPE" })}
          value={data.total_jenis_sampah}
        />
      </div>
      <div className="col-xl-6">
        <StatisticsWidget
          className="card-xl-stretch mb-xl-8"
          image="abstract-4.svg"
          title={intl.formatMessage({ id: "STATISTIC-REVENUE-VALUE" })}
          value={data.total_uang_omset}
        />
      </div>
      <div className="col-xl-6">
        <StatisticsWidget
          className="card-xl-stretch mb-xl-8"
          image="abstract-4.svg"
          title={intl.formatMessage({ id: "STATISTIC-TOTAL-BANK" })}
          value={data.total_bank_sampah}
        />
      </div>
    </div>
  );
}

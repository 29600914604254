/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";

import axios from "axios";
import moment from "moment";
import DataTable, { TableColumn } from "react-data-table-component";
import { KTIcon } from "src/_metronic/helpers";
import { IDRFormat, exportToExcel } from "src/app/helpers/utils";
import { ITransactionResponse } from "src/types/ITransactionResponse";
import { ExcelSettings } from "./ExcelSettings";

const TransactionWrapper: FC = () => {
  const intl = useIntl();
  const [list, setList] = useState<ITransactionResponse[]>([])
  const [searchTerm, setSearchTerm] = useState("");

  const COLUMNS: TableColumn<ITransactionResponse>[] = [
    {
      name: 'Date',
      sortable: true,
      width: "",
      selector: (item) => moment(item.date).format("DD/MM/YYYY"),
      cell: (item) => moment(item.date).format("DD/MM/YYYY"),
    },
    {
      name: intl.formatMessage({ id: "STATISTIC-TYPE" }),
      sortable: true,
      width: "150px",
      selector: (item) => item.jenis_sampah,
      cell: (item) => item.jenis_sampah,
    },
    {
      name: intl.formatMessage({ id: "TABLE-DESCRIPTION" }),
      sortable: true,
      width: "150px",
      selector: (item) => item.description,
      cell: (item) => item.description,
    },
    {
      name: intl.formatMessage({ id: "TABLE-NAME" }),
      sortable: true,
      width: "",
      selector: (item) => item.name,
      cell: (item) => item.name,
    },
    {
      name: "Admin Name",
      width: "",
      sortable: true,
      selector: (item) => item.nama_admin,
      cell:  (item) => item.nama_admin,
    },
    {
      name:`${intl.formatMessage({ id: "TABLE-NAME" })} ${intl.formatMessage({ id: "MENU-CUSTOMER" })}`,
      sortable: true,
      width: "",
      selector: (item) => item.nasabah_name,
      cell: (item) => item.nasabah_name
    },
    {
      name:`${intl.formatMessage({ id: "TABLE-GENDER" })}`,
      sortable: true,
      width: "",
      selector: (item) => item.gender,
      cell: (item) => item.gender
    },
    {
      name: 'Birthdate',
      sortable: true,
      width: "",
      selector: (item) => item.birthdate.toString(),
      cell: (item) => <div>{`${item.birthdate}`}</div>,
    },
    {
      name: intl.formatMessage({ id: "TABLE-WEIGHT" }),
      sortable: true,
      width: "",
      selector: (item) => IDRFormat(item.weight),
      cell: (item) => IDRFormat(item.weight)
    },
    {
      name: 'Price',
      sortable: true,
      width: "",
      selector: (item) => item.price,
      cell: (item) => IDRFormat(item.price)
    },
    {
      name: 'Total',
      sortable: true,
      width: "",
      selector: (item) => item.total,
      cell: (item) => IDRFormat(item.total)
    },
  
  ];

  const fetchList =  async () => {
    try {
      const  response = await axios.post(`${process.env.REACT_APP_API_URL}data/dashboard/get-all-transaction`, {
        country: "PH",
      })
      setList(response?.data?.data)
    } catch (e) {
      setList([])
    }
  }

  useEffect(()=> {
    fetchList()
  }, [])


  const filteredList = list.filter((item) =>
  Object.values(item).some(
    (value) =>
      value &&
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
  )
);

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU-TRANSACTION" })}
      </PageTitle>
      <div className="card">
        <div className="card-header border-0 pt-5">
          <div className="d-flex align-items-center gap-2">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">
                {intl.formatMessage({ id: "MENU-TRANSACTION" })}
              </span>
            </h3>
            
            <div>
              <button type='button' className='btn btn-sm btn-light-primary' onClick={() =>  exportToExcel(list, ExcelSettings, 'Transaction')}>
                <KTIcon iconName='exit-up' className='fs-2' />
                Export
              </button>
            </div>
          </div>
          <div
            className="card-toolbar"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-trigger="hover"
            title="Click to add a user"
          >
            <input
              id="search"
              className="form-control form-control-solid w-250px"
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <div className="card-body py-3">
          {
            list.length> 0  && <DataTable columns={COLUMNS} data={filteredList} pagination  />
          }
        </div>
      </div>
    </>
  );
};

export { TransactionWrapper };

/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { toAbsoluteUrl } from "../../../helpers";
import { setLanguage, useLang } from "../../../i18n/Metronici18n";
type Props = {
  toggleBtnClass?: string;
  toggleBtnIconClass?: string;
  menuPlacement?: string;
  menuTrigger?: string;
};

const languages = [
  {
    lang: "en",
    name: "English",
    flag: toAbsoluteUrl("/media/flags/united-states.svg"),
  },
  {
    lang: "id",
    name: "Indonesia",
    flag: toAbsoluteUrl("/media/flags/indonesia.svg"),
  },
  {
    lang: "ph",
    name: "Philippines",
    flag: toAbsoluteUrl("/media/flags/philippines.svg"),
  },
];

const Languages = ({
  toggleBtnClass = "",
  menuPlacement = "bottom-end",
  menuTrigger = "{default: 'click', lg: 'hover'}",
}: Props) => {
  const lang = useLang();
  const currentLanguage = languages.find((x) => x.lang === lang);
  return (
    <a
      href="#"
      className={clsx(" ", toggleBtnClass)}
      data-kt-menu-trigger={menuTrigger}
      data-kt-menu-attach="parent"
      data-kt-menu-placement={menuPlacement}
    >
     
        <span className="menu-title position-relative ">
          <span className="d-flex align-items-center fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
            {currentLanguage?.name}{" "}
            <img
              className="w-15px h-15px rounded-1 ms-2"
              src={currentLanguage?.flag}
              alt="metronic"
            />
          </span>
        </span>
   

      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px"
        data-kt-menu="true"
      >
        {languages.map((l) => (
          <div
            className="menu-item px-3"
            key={l.lang}
            onClick={() => {
              setLanguage(l.lang);
            }}
          >
            <div
              className={clsx("menu-link d-flex px-5", {
                active: l.lang === currentLanguage?.lang,
              })}
            >
              <span className="symbol symbol-20px me-4">
                <img className="rounded-1" src={l.flag} alt="metronic" />
              </span>
              {l.name}
            </div>
          </div>
        ))}
      </div>
    </a>
  );
};

export { Languages };

import { KTIcon } from "src/_metronic/helpers";

function TableHeader({ header, onSort, sort, sort_by }) {
  return (
    <thead>
      <tr className="fw-bold text-muted">
        {header?.map((item) => {
          return (
            <th
              className="cursor-pointer"
              key={item.id}
              style={{ minWidth: item.name !== "No" ? 100 : 50 }}
              onClick={() => item.sort ? onSort(item.key) : null}
            >
              {" "}
              <span className="sorting-item">
                {item.name}{" "}
                {item.sort && (
                  <>
                    <span
                      className={`sorting-icon ${
                        sort_by === item.key && sort === "ASC"
                        ? "opacity-100"
                        : "opacity-10"
                      }`}
                    >
                      <KTIcon
                        iconName="arrow-up"
                        className={`cursor-pointer fs-3 text-primary `}
                      />
                    </span>
                    <span
                      className={`sorting-icon ${
                        sort_by === item.key && sort === "DESC"
                          ? "opacity-100"
                          : "opacity-10"
                      }`}
                    >
                      <KTIcon
                        iconName="arrow-down"
                        className={`cursor-pointer fs-3 text-primary`}
                      />
                    </span>
                  </>
                )}
              </span>
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

export default TableHeader;

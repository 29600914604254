import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";

const useServices = (REPORT_URL) => {
  const [filter, setFilter] = useState({
    search_text: "",
    limit: 10,
    start_date: `${moment(new Date()).format("YYYY-MM-DD")}`,
    end_date: `${moment(new Date()).format("YYYY-MM-DD")}`,
    start_time: "00:00:00",
    end_time: "23:59:00",
    sort: "DESC",
    sort_by: "transaction_id",
    page: 1,
  });
  
  const [tableLoading, setTableLoading] = useState<Boolean>(false);
  const [reportData, setReportData] = useState<any>();

  const filterToString = (filter) => {
    const {
      search_text,
      limit,
      start_date,
      end_date,
      start_time,
      end_time,
      sort,
      sort_by,
      page,
    } = filter;
    const queryString = `?search_text=${search_text}&limit=${limit}&start_date=${start_date} ${start_time}&end_date=${end_date} ${end_time}&sort=${sort}&sort_by=${sort_by}&page=${page}`;
    return queryString;
  };

  useEffect(() => {
    getReport(filter);
  }, [filter]);

  const getReport = (filter) => {
    setTableLoading(true)
    const queryString = filterToString(filter);
    try {
      axios.get(`${REPORT_URL}${queryString}`).then((response) => {
        setReportData(response.data);
        setTableLoading(false)
      });
    } catch (err) {}
  };

  const onPageChange = (x) => {
    setFilter({ ...filter, page: Number(x) });
  };

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleDateRangeChange = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    setDateRange([ranges.selection]);
    setFilter({
      ...filter,
      start_date: `${moment(startDate).format("YYYY-MM-DD")}`,
      end_date: `${moment(endDate).format("YYYY-MM-DD")}`,
      page: 1,
    });
  };

  const handleTimeStart = (newTime) => {
    setFilter({
      ...filter,
      start_time: `${newTime.target.value}:00`,
    });
  };

  const handleTimeEnd = (newTime) => {
    setFilter({
      ...filter,
      end_time: `${newTime.target.value}:00`,
    });
  };

  const onSort = (key) => {
    setFilter({
      ...filter,
      sort: filter.sort === 'ASC' ? 'DESC' : 'ASC',
      sort_by: key
    });
  }

  return {filter, setFilter, reportData, onPageChange, dateRange, setDateRange, handleDateRangeChange, handleTimeStart, handleTimeEnd, onSort, tableLoading};
};

export { useServices };

import moment from "moment";
import React from "react";
import { IDRFormat, formatDate } from "../../../../app/helpers/utils";
import 'moment/locale/id' 
import Loading from "../loading/Loading";
import { useIntl } from "react-intl";
moment.locale('id')

function TableBody({ header, rowData, loading }) {
  const intl = useIntl();
  return (
    <tbody>
    {
      !loading ? 
        rowData?.length > 0  ? 
          rowData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {header?.map((item) => {
                if(item.type === 'date') {
                  return <td key={item.id}>{formatDate(row[item.key])}</td>
                }
                if(item.type === 'price') {
                  return <td key={item.id}>{IDRFormat(row[item.key])}</td>
                }
                return  <td key={item.id}>{row[item.key]}</td>
              }
              )}
            </tr>
          )) : <tr><td colSpan={header?.length}><span style={{ display: "block", position:'absolute', zIndex:10 }}>{intl.formatMessage({ id: "TABLE.INFO.NODATA" })}</span></td></tr>
          : <tr>
          <td colSpan={header?.length} className="position-relative"> <Loading /></td>
      </tr>
    }
  
  </tbody>
  );
}

export default TableBody;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";

function TablePagination({
  page,
  limit,
  total,
  last_page,
  onPageChange,
}) {
  const totalPages = Math.ceil(total / limit);
  const intl = useIntl();
  return (
    <div className="row">
      <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
      <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
        <div id="kt_table_users_paginate">
          <ul className="pagination">
            <li
              className={clsx("page-item", {
                disabled: page === 1,
              })}
            >
              <a
                onClick={() => onPageChange(1)}
                style={{ cursor: "pointer" }}
                className="page-link"
              >
                {intl.formatMessage({ id: "PAGGING.FIRST" })}
              </a>
            </li>
            <li
              className={clsx("page-item", {
                disabled: page === 1,
              })}
            >
              <a
                onClick={() => onPageChange(page - 1)}
                style={{ cursor: "pointer" }}
                className="page-link"
              >
                {intl.formatMessage({ id: "PAGGING.PREV" })}
              </a>
            </li>
            <li
              className={clsx("page-item", {
                // disabled: page === pagination.links?.length! - 2,
              })}
            >
              <span className="page-link">
              {intl.formatMessage({ id: "PAGGING.PAGE" })} {page}  {intl.formatMessage({ id: "PAGGING.OF" })} {totalPages}
              </span>
            </li>

            <li
              className={clsx("page-item", {
                disabled: page === last_page,
              })}
            >
              <a
                onClick={() => onPageChange(page + 1)}
                style={{ cursor: "pointer" }}
                className="page-link"
              >
                 {intl.formatMessage({ id: "PAGGING.NEXT" })}
              </a>
            </li>
            {/* {paginationLinks
              ?.map((link) => {
                return {...link, label: mappedLabel(link.label)}
              })
              .map((link) => (
                <li
                  key={link.label}
                  className={clsx('page-item', {
                    active: pagination.page === link.page,
                    disabled: isLoading,
                    previous: link.label === 'Previous',
                    next: link.label === 'Next',
                  })}
                >
                  <a
                    className={clsx('page-link', {
                      'page-text': link.label === 'Previous' || link.label === 'Next',
                      'me-5': link.label === 'Previous',
                    })}
                    onClick={() => updatePage(link.page)}
                    style={{cursor: 'pointer'}}
                  >
                    {mappedLabel(link.label)}
                  </a>
                </li>
              ))} */}

            <li
              className={clsx("page-item", {
                disabled: page === totalPages,
              })}
            >
              <a
                onClick={() => onPageChange(last_page)}
                style={{ cursor: "pointer" }}
                className="page-link"
              >
                 {intl.formatMessage({ id: "PAGGING.LAST" })}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TablePagination;

// Export settings
export const ExcelSettings = [
    {
      name: "ID",
      key: "nasabah_id",
    },
    {
      name: "Name",
      key: "name",
    },
    {
      name: "Last name",
      key: "lastname",
    },
    {
      name: "Address",
      key: "address",
    },
    {
      name: "Phone",
      key: "phone",
    },
    {
      name: "Email",
      key: "email",
    },
    {
      name: "Bank ID",
      key: "bank_id",
    },
    {
      name: "Status",
      key: "status",
    },
    {
      name: "Username",
      key: "username",
    },
    {
      name: "Birthdate",
      key: "birthdate",
    },
    {
      name: "Gender",
      key: "gender",
    },
    {
      name: "Country",
      key: "country",
    },
  ]

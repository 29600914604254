/* eslint-disable jsx-a11y/anchor-is-valid */

import axios from "axios";
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom"; // Import useParams
import { IDRFormat } from "src/app/helpers/utils";
import LatestRequest from "src/app/modules/latest-request/LatestRequest";
import LatestTransaction from "src/app/modules/latest-transaction/LatestTransaction";
import { IDetailCustomer } from "./model/IDetailCustomer";

const DetailCustomer: FC = () => {
  const intl = useIntl();
  const { id } = useParams();
  const [detail, setDetail] = useState<IDetailCustomer>();

  const fetchDetail = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}nasabah/detail`,
        {
          nasabah_id: id,
        }
      );
      setDetail(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchDetail();
  }, []);

  return (
    <>
      <div className="row g-5 g-xl-8">
        <div className="col-xl-6">
          <div className="card">
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  {intl.formatMessage({ id: "MENU-CUSTOMER-EDIT" })}
                </span>
              </h3>
            </div>
            <div className="card-body py-3">
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">ID</label>

                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {detail?.nasabah?.[0].nasabah_id}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">
                  {" "}
                  {intl.formatMessage({ id: "TABLE-NAME" })}
                </label>

                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {detail?.nasabah?.[0].name}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">
                  {" "}
                  {intl.formatMessage({ id: "TABLE-LAST-NAME" })}
                </label>

                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {detail?.nasabah?.[0].lastname}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">
                  {" "}
                  {intl.formatMessage({ id: "TABLE-ADDRESS" })}
                </label>

                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {detail?.nasabah?.[0].address}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">
                  {" "}
                  {intl.formatMessage({ id: "TABLE-PHONE-NUMBER" })}
                </label>

                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {detail?.nasabah?.[0].phone}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">
                  {" "}
                  {intl.formatMessage({ id: "TABLE-EMAIL" })}
                </label>

                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {detail?.nasabah?.[0].email}
                  </span>
                </div>
              </div>

              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">
                  {" "}
                  {intl.formatMessage({ id: "TABLE-BIRTHDAY" })}
                </label>

                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {detail?.nasabah?.[0].birthdate.toString()}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">
                  {" "}
                  {intl.formatMessage({ id: "TABLE-GENDER" })}
                </label>

                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {detail?.nasabah?.[0].gender}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="card">
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  {intl.formatMessage({ id: "TABLE-BANK" })}
                </span>
              </h3>
            </div>
            <div className="card-body py-3">
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">
                  {" "}
                  {intl.formatMessage({ id: "TABLE-BANK" })} ID
                </label>

                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {detail?.nasabah_bank?.[0].bank_id}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">
                  {" "}
                  {intl.formatMessage({ id: "TABLE-NAME" })}
                </label>

                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
             
                    <a href={`/bank/${detail?.nasabah_bank?.[0].bank_id}`}>{detail?.nasabah_bank?.[0].bank_name}</a>
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">
                  {" "}
                  {intl.formatMessage({ id: "TABLE-BALANCE" })}
                </label>

                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">
                    {IDRFormat(detail?.nasabah_bank?.[0].balance)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LatestRequest transaction={detail?.nasabah_request}/>
        <LatestTransaction transaction={detail?.nasabah_transaction}/>
      </div>
    </>
  );
};

export { DetailCustomer };

// Export settings
export const ExcelSettings = [
    {
      name: "Item ID",
      key: "item_id",
    },
    {
      name: "Item Code",
      key: "item_code",
    },
    {
      name: "Name",
      key: "name",
    },
    {
      name: "Description",
      key: "description",
    },
    {
      name: "Image Path",
      key: "image_path",
    },
    {
      name: "Price",
      key: "price",
    },
    {
      name: "Per Kg",
      key: "perkg",
    },
    {
      name: "Metric",
      key: "metric",
    },
    {
      name: "Status",
      key: "status",
    },
    {
      name: "Bank ID",
      key: "bank_id",
    },
    {
      name: "Bank Name",
      key: "bank_name",
    },
    {
      name: "Is Default?",
      key: "is_default",
    },
    {
      name: "Country",
      key: "country",
    },
    {
      name: "Gender",
      key: "gender",
    },
    {
      name: "Weight",
      key: "weight",
    },
  ]

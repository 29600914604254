import axios from 'axios'
import {dataTypeLogs} from  '../../../models/logs/LogsModel'
import {getNow} from '../../../helpers/utils'
import {getAuth} from '../../../modules/auth/core/AuthHelpers'

const API_URL = process.env.REACT_APP_API_URL

export const LOGS_URL = `${API_URL}/logs`

export async function storeAccessLogs(pathname:string) : Promise<{}> {
    const logData = {
        message: `Access route ${pathname}`,
        log_type: dataTypeLogs.TYPE_ACCCESS,
        created_at: getNow(),
        route_access: `${window.location.origin}${pathname}`,
        ip_address: null,
    }
    
    return await axios.post(LOGS_URL, logData)
}

export async function storeErrorLogs(message:string) : Promise<{}> {
    const logData = {
        message,
        log_type: dataTypeLogs.TYPE_ERROR,
        created_at: getNow(),
    }

    return await axios.post(LOGS_URL, logData)
}

export async function storeHitApiLogs(
    message:string,
    route_access:string,
    params:string,
    response:string,
    request_timestamp:string,
    response_timestamp:string,
) : Promise<{}> {
    const logData = {
        message,
        log_type: dataTypeLogs.TYPE_HIT_API,
        created_at: getNow(),
        route_access,
        ip_address: null,
        params,
        response,
        request_timestamp,
        response_timestamp
    }

    return await axios.post(LOGS_URL, logData)
}

export async function storeUserActivityLogs(
    route_access:string,
    request_timestamp:string,
) : Promise<{}> {

    const auth = getAuth()
    let user_id: number | null = null

    if (auth && auth.id) {
        user_id = auth.id
    }

    const logData = {
        user_id,
        message: `Access route ${route_access}`,
        log_type: dataTypeLogs.TYPE_USER,
        created_at: getNow(),
        route_access,
        ip_address: null,
        request_timestamp,
        response_timestamp: getNow()
    }

    return await axios.post(LOGS_URL, logData)
}
// Export settings
export const ExcelSettings = [
    {
      name: "Name",
      key: "name",
    },
    {
      name: "Phone",
      key: "phone",
    },
    {
      name: "Email",
      key: "email",
    },
    {
      name: "Type",
      key: "type",
    },
   
  ]

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import { useAuth } from '../../../../app/modules/auth'
import {KTIcon} from '../../../helpers'
import { useIntl } from "react-intl";
const SidebarFooter = () => {
  const {
    // currentUser, 
    logout} = useAuth()
    const intl = useIntl();
  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
      <a
        onClick={logout} 
        className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'
       
      >
        <span className='btn-label'> {intl.formatMessage({ id: "OTHER.LOGOUT" })}</span>
        <KTIcon iconName='document' className='btn-icon fs-2 m-0' />
      </a>
    </div>
  )
}

export {SidebarFooter}

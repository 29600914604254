export interface IEditBank {
  name: string;
  address: string;
  kota: string;
  kelurahan: string;
  kecamatan: string;
  phone: string;
  visi: string;
  misi: string;
  latitude: string;
  longitude: string;
  bank_id: number;
}

export const stateEditBank: IEditBank = {
  name: '',
  address:'',
  kota:'',
  kelurahan: '',
  kecamatan: '',
  phone:'',
  visi:'',
  misi:'',
  latitude:'',
  longitude:'',
  bank_id: 0,
};

import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { reInitMenu } from '../helpers'
import { ActivityDrawer, AddAdmin, AddBank, AddCustomer, AddDefaultItem, DrawerMessenger } from '../partials'
import { RightToolbar } from '../partials/layout/RightToolbar'
import { EditAdmin } from '../partials/modals/edit-admin/EditAdmin'
import { EditBank } from '../partials/modals/edit-bank/EditBank'
import { EditCustomer } from '../partials/modals/edit-customer/EditCustomer'
import { EditDefaultItem } from '../partials/modals/edit-default-item/EditDefaultItem'
import { Content } from './components/content'
import { FooterWrapper } from './components/footer'
import { HeaderWrapper } from './components/header'
import { ScrollTop } from './components/scroll-top'
import { Sidebar } from './components/sidebar'
import { ToolbarWrapper } from './components/toolbar'
import { PageDataProvider } from './core'


const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
        <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
          <HeaderWrapper />
          <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
            <Sidebar />
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
              <div className='d-flex flex-column flex-column-fluid'>
                <ToolbarWrapper />
                <Content>
                  <Outlet />
                </Content>
              </div>
              <FooterWrapper />
            </div>
          </div>
        </div>
      </div>

     

    

      {/* begin:: Drawers */}
      <ActivityDrawer />
      <RightToolbar />
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <AddCustomer />
      <EditCustomer />
      <EditDefaultItem />
      <AddAdmin />
      <EditAdmin/>
      <AddBank />
      <EditBank />
      <AddDefaultItem />

      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  )
}

export { MasterLayout }


/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl";
import { sidebar } from "../../../../../constant/sidebar";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
interface Items {
  name: string,
  pathname: string,
  fontIcon: string,
  icon: string,
  children: [],
}
const SidebarMenuMain = () => {
  const intl = useIntl();
  let authData : any = localStorage.getItem('binbank-auth');
    const filteredMenuData = sidebar.map(menu => {
      // if(menu.name === "MENU-DATA" && JSON.parse(authData).email === "adminboi@gmail.com") {
      //   menu.children = menu.children.filter(child => child.name !== "MENU-DATA.SALESBOKG");
      // }
      // if(menu.name === "MENU-DATA" && JSON.parse(authData).email === "adminbokg@gmail.com") {
      //   menu.children = menu.children.filter(child => child.name !== "MENU-DATA.SALESBOI");
      // }
      return menu;
    });
  return (
    <>
      {filteredMenuData.map((item, index) => {
        if (item?.children?.length === 0) {
          return (
            <SidebarMenuItem
              key={index}
              to={item?.pathname}
              icon={item?.icon}
              title={intl.formatMessage({ id: item?.name })}
              fontIcon={item?.fontIcon}
            />
          );
        }
        if (item?.children?.length > 0) {
          return (
            <SidebarMenuItemWithSub
              key={index}
              to={item?.pathname}
              icon={item?.icon}
              title={intl.formatMessage({ id: item?.name })}
              fontIcon={item?.fontIcon}
            >
              {/* {item?.children?.map((child, index) => {
                if (child?.children?.length > 0) {
                  return (
                    <SidebarMenuItemWithSub
                      key={index}
                      to={item?.pathname}
                      icon={item?.icon}
                      title={intl.formatMessage({ id: item?.name })}
                      fontIcon={item?.fontIcon}
                    >
                      {child?.children?.map(
                        (child2: any, index) => {
                          return (
                            <SidebarMenuItem
                              key={index}
                              to={child2.pathname}
                              title={intl.formatMessage({ id: child2?.name })}
                              hasBullet={true}
                            />
                          );
                        }
                      )}
                    </SidebarMenuItemWithSub>
                  );
                } else {
                  return (
                    <SidebarMenuItem
                      key={index}
                      to={child?.pathname}
                      title={intl.formatMessage({ id: child?.name })}
                      hasBullet={true}
                    />
                  );
                }
              })} */}
            </SidebarMenuItemWithSub>
          );
        }
      })}
    </>
  );
};

export { SidebarMenuMain };
